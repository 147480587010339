import React from 'react'
import parse from 'html-react-parser';

import './styles.css'

const LineImageCard = (props) => {
  const { preferences, cards } = props.lineImage;

  return (
    <>
      <div className='line-image-card-content'>
        <div className='line-image-card-box-left'>
          <p className={preferences.tablet ? 'line-image-card-card-tablet-title' : 'line-image-card-card-title'}>{parse(preferences.title)}</p>
          <p className={preferences.tablet ? 'line-image-card-tablet-text' : 'line-image-card-text'}>{parse(preferences.text)}</p>
          <div className='line-image-card-img-box'>
            <img
              alt="image"
              src={`/tec/any_type/img/${cards[0].img}.svg`}
              className="line-image-card-img"
            />
          </div>
        </div>
        <div className={preferences.tablet ? 'line-image-card-box-right-tablet' : 'line-image-card-box-right'}>
          <div className='line-image-card-divider'>
            <img
              alt="circle"
              src={`/tec/any_type/circle/${cards[0].color}.png`}
              className="line-image-card-circle"
            />
          </div>
          <div className='line-image-card-content-box'>
            <img
              alt="image"
              src={`/tec/any_type/icon/${cards[0].icon}.svg`}
              className="line-image-card-icon"
            />
            <p className={preferences.tablet ? 'line-image-card-card-tablet-title' : 'line-image-card-card-title'}>{parse(cards[0].title)}</p>
            {cards[0].texts.map(e => <p className={preferences.tablet ? 'line-image-card-tablet-text' : 'line-image-card-text'}>{parse(e.text)}</p>)}
          </div>
        </div>
      </div>
      <div className='line-image-card-content'>
        <div className='line-image-card-img-box'>
          <img
            alt="image"
            src={`/tec/any_type/img/${cards[1].img}.svg`}
            className="line-image-card-img"
          />
        </div>
        <div className={preferences.tablet ? 'line-image-card-box-right-tablet' : 'line-image-card-box-right'}>
          <div className='line-image-card-divider-blue'>
            <img
              alt="circle"
              src={`/tec/any_type/circle/${cards[1].color}.png`}
              className="line-image-card-circle-blue"
            />
          </div>
          <div className='line-image-card-content-box'>
            <img
              alt="image"
              src={`/tec/any_type/icon/${cards[1].icon}.png`}
              className="line-image-card-icon"
            />
            <p className={preferences.tablet ? 'line-image-card-card-tablet-title' : 'line-image-card-card-title'}>{parse(cards[1].title)}</p>
            {cards[1].texts.map(e => <p className={preferences.tablet ? 'line-image-card-tablet-text' : 'line-image-card-text'}>{parse(e.text)}</p>)}
          </div>
        </div>
      </div>
      <div className='line-image-card-content'>
        <div className='line-image-card-img-box'>
          <img
            alt="image"
            src={`/tec/any_type/img/${cards[2].img}.svg`}
            className="line-image-card-img"
          />
        </div>
        <div className={preferences.tablet ? 'line-image-card-box-right-tablet' : 'line-image-card-box-right'}>
          <div className='line-image-card-divider-green'>
            <img
              alt="circle"
              src={`/tec/any_type/circle/${cards[2].color}.png`}
              className="line-image-card-circle-green"
            />
          </div>
          <div className='line-image-card-content-box'>
            <img
              alt="image"
              src={`/tec/any_type/icon/${cards[2].icon}.png`}
              className="line-image-card-icon"
            />
            <p className={preferences.tablet ? 'line-image-card-card-tablet-title' : 'line-image-card-card-title'}>{parse(cards[2].title)}</p>
            {cards[2].texts.map(e => <p className={preferences.tablet ? 'line-image-card-tablet-text' : 'line-image-card-text'}>{parse(e.text)}</p>)}
          </div>
        </div>
      </div>
    </>
  )
}

export default LineImageCard
