import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './casos_uso_desktop.css'

import LanguageSelect from '../../components/languageSelect/languageSelect'
import DynamicImage from '../../components/dynamic-image'
import FlipCardIconRow from '../../components/casos_uso/flip-card-icon-row'
import CtaDesktop from '../../components/common/cta/desktop'
import Footer from '../../components/common/footer/desktop'

const CasosUsoDesktop = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  const [arrow, setArrow] = useState(false);
  const btnVideoArrow = {
    black: "/common/arrows/complete/black/right.png",
    white: "/common/arrows/complete/white/right.png"
  }

  const images = [
    { image: t("use_cases_dynamic_image_01") },
    { image: t("use_cases_dynamic_image_02") },
    { image: t("use_cases_dynamic_image_03") },
    { image: t("use_cases_dynamic_image_04") }
  ]

  const flipCards = {
    preferences: {
      isDesktop: true
    },
    cards: [
      {
        isCurrentCard: false,
        img: "reception",
        title: t("use_cases_text04_00"),
        txtBtn: t("text_read_more"),
        icon: "delivery",
        reverseTitle: t("use_cases_text04_01"),
        description: t("use_cases_text04_02")
      },
      {
        isCurrentCard: false,
        img: "docs",
        title: t("use_cases_text05_00"),
        txtBtn: t("text_read_more"),
        icon: "collection",
        reverseTitle: t("use_cases_text05_01"),
        description: t("use_cases_text05_02")
      },
      {
        isCurrentCard: false,
        img: "plane",
        title: t("use_cases_text06_00"),
        txtBtn: t("text_read_more"),
        icon: "expenses",
        reverseTitle: t("use_cases_text06_01"),
        description: t("use_cases_text06_02")
      },
      {
        isCurrentCard: false,
        img: "shield",
        title: t("use_cases_text07_00"),
        txtBtn: t("text_read_more"),
        icon: "identity",
        reverseTitle: t("use_cases_text07_01"),
        description: t("use_cases_text07_02")
      },
      {
        isCurrentCard: false,
        img: "card",
        title: t("use_cases_text08_00"),
        txtBtn: t("text_read_more"),
        icon: "credit",
        reverseTitle: t("use_cases_text08_01"),
        description: t("use_cases_text08_02")
      },
      {
        isCurrentCard: false,
        img: "phone",
        title: t("use_cases_text09_00"),
        txtBtn: t("text_read_more"),
        icon: "onboarding",
        reverseTitle: t("use_cases_text09_01"),
        description: t("use_cases_text09_02")
      }
    ]
  };

  return (
    <div className="casos-uso-container">
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <header className="header">
        <div className='header-content'>
          <div className='header-logo'>
            <Link onClick={scrollToTop} to="/" className="header-logo-link">
              <img
                alt="picaio-logo"
                src="/common/header/logo.png"
              />
            </Link>
          </div>
          <div class='header-nav-list'>
            <div className='header-nav-views'>
              <Link onClick={scrollToTop} to="/" className="header-link">{t("nav_text01")}</Link>
              <Link onClick={scrollToTop} to="/casos_uso" className="header-link header-link-active">{t("nav_text02")}</Link>
              <Link onClick={scrollToTop} to="/tecnologia" className="header-link">{t("nav_text03")}</Link>
              <Link onClick={scrollToTop} to="/quienes_somos" className="header-link">{t("nav_text04")}</Link>
            </div>
            <div className='header-btn-box'>
              <Link onClick={scrollToTop} to="/contacto" className="header-btn">{t("nav_text05")}</Link>
            </div>
            <div className='header-lang-box'><LanguageSelect /></div>
          </div>
        </div>
      </header>
      <section id="inicio" className="casos-uso-section">
        <div className='casos-uso-content'>
          <div className='casos-uso-text'>
            <p className='casos-uso-title'>{parse(`${t("use_cases_text01")}`)}</p>
            <p className='casos-uso-paragraph'>{parse(t("use_cases_text02"))}</p>
          </div>
          <Link
            onClick={scrollToTop}
            to="/contacto"
            className="casos-uso-btn"
            onMouseEnter={() => setArrow(true)}
            onMouseLeave={() => setArrow(false)}
          >
            {t("use_cases_text03")}
            <img
              alt="arrow-right"
              src={arrow ? btnVideoArrow.white : btnVideoArrow.black}
            />
          </Link>
        </div>
        <div className='casos-uso-img-box'>
          <DynamicImage images={images} />
        </div>
      </section>
      <section id="cards" className="casos-uso-cards-section">
        <FlipCardIconRow flipCards={flipCards} />
      </section>
      <CtaDesktop ctaText={t("cta_text04")} />
      <Footer />
    </div>
  )
}

export default CasosUsoDesktop
