import React from 'react'
import { useMediaQuery } from 'react-responsive'

import TerminosCondicionesDesktop from './terminos_condiciones/terminos_condiciones_desktop'
import TerminosCondicionesTablet from './terminos_condiciones/terminos_condiciones_tablet'
import TerminosCondicionesMobile from './terminos_condiciones/terminos_condiciones_mobile'

const TerminosCondiciones = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return <>
    {isMobile && !isTablet && !isDesktop && <TerminosCondicionesMobile />}
    {isTablet && !isDesktop && <TerminosCondicionesTablet />}
    {isDesktop && <TerminosCondicionesDesktop />}
  </>
}

export default TerminosCondiciones
