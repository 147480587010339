import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser'

import './styles.css';

import DynamicContent from './dynamic-content';

const DynamicCardTablet = (props) => {
  const { preferences, cards } = props.dynamicCards

  const [currentCards, setCurrentCards] = useState(cards);
  useEffect(() => setCurrentCards(cards), [cards]);
  const cardClicked = (card) => setCurrentCards(currentCards.map(e => e.title === card.title ? { ...e, isCurrentCard: true } : { ...e, isCurrentCard: false }));

  return (
    <div className="dynamic-card-tablet-content">
      <div className='dynamic-card-tablet-text-box'>
        <p className='dynamic-card-tablet-section-title'>{parse(preferences.title)}</p>
        <p className='dynamic-card-tablet-title'>{parse(preferences.subtitle)}</p>
      </div>
      {currentCards.map(card =>
        <DynamicContent
          key={card.title}
          card={card}
          onCardClicked={cardClicked}
        />
      )}
    </div>
  )
}

export default DynamicCardTablet
