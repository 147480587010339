import React, { useState, useEffect, useRef } from 'react';
import { useInView } from "react-intersection-observer";
import parse from 'html-react-parser';

import './styles.css';

let latest;

const DynamicScroll = (props) => {

  const { boxLeft, boxRight } = props;

  const [currentboxLeft, setboxLeft] = useState(boxLeft);
  const [currentboxRight, setboxRight] = useState(boxRight);
  useEffect(() => setboxLeft(boxLeft), [boxLeft]);
  useEffect(() => setboxRight(boxRight), [boxRight]);

  const [currentTop, setCurrentTop] = useState(3200);
  const [showBOX, setShowAbs] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const startY = limitTop.current.getBoundingClientRect()
      const customleftBox = leftBox.current.getBoundingClientRect()
      const endY = limitBottom.current.getBoundingClientRect()

      if (startY.y > 0 && endY.y > 0) {
        setShowAbs(true)
        setCurrentTop(Math.abs(window.pageYOffset) + Math.abs(startY.y))
        return
      }

      if (startY.y < 0 && ((endY.y - 200) > Math.abs(customleftBox.height))) {
        setShowAbs(false)
        latest = Math.abs(window.pageYOffset)
        return
      }

      setCurrentTop(Math.abs(latest))
      setShowAbs(true)
    });
  }, []);

  const imageRef = useRef(null);
  const limitTop = useRef(null);
  const leftBox = useRef(null);
  const limitBottom = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({ threshold: 0.4 });
  const { ref: secondContainer, inView: secondInView } = useInView({ threshold: 0.4 });
  const { ref: thirdContainer, inView: thirdInView } = useInView({ threshold: 0.4 });

  const getImage = () => {
    if (firstInView)
      return `/tec/any_type/img/${currentboxRight[0].image}.svg`;
    if (secondInView)
      return `/tec/any_type/img/${currentboxRight[1].image}.svg`;
    return `/tec/any_type/img/${currentboxRight[2].image}.svg`;
  };

  return (
    <>
      <div ref={limitTop}></div>
      <div className='dynamic-scroll-box-left' ref={leftBox} style={
        showBOX ?
          { top: currentTop + 100, position: 'absolute' }
          : { top: '115px', position: 'fixed' }
      }>
        <div className='dynamic-scroll-content-box-left'>
          <p className='dynamic-scroll-left-title'>{parse(currentboxLeft.title)}</p>
          <p className='dynamic-scroll-left-text'>{parse(currentboxLeft.text)}</p>
        </div>
        <img src={getImage()} alt="dynamic-scroll-image" ref={imageRef} className="dynamic-scroll-image" />
      </div>
      <div className='dynamic-scroll-box-right' ref={firstContainer}>
        <div className='dynamic-scroll-divider' style={{height: currentboxRight[0].height}}>
          <img
            alt="dynamic-scroll-circle"
            src={`/tec/any_type/circle/${currentboxRight[0].color}.png`}
            className="dynamic-scroll-circle"
          />
        </div>
        <div className='dynamic-scroll-right-content'>
          <img
            alt="dynamic-scroll-icon"
            src={`/tec/any_type/icon/${currentboxRight[0].icon}.svg`}
            className="dynamic-scroll-icon"
          />
          <p className='dynamic-scroll-right-title'>{parse(currentboxRight[0].title)}</p>
          {currentboxRight[0].text.map(e => <p className='dynamic-scroll-text'>{parse(e)}</p>)}
        </div>
      </div>
      <div className='dynamic-scroll-box-right' ref={secondContainer}>
        <div className='dynamic-scroll-divider-blue' style={{height: currentboxRight[1].height}}>
          <img
            alt="dynamic-scroll-circle-blue"
            src={`/tec/any_type/circle/${currentboxRight[1].color}.png`}
            className="dynamic-scroll-circle dynamic-scroll-circle-blue"
          />
        </div>
        <div className='dynamic-scroll-right-content'>
          <img
            alt="dynamic-scroll-icon"
            src={`/tec/any_type/icon/${currentboxRight[1].icon}.png`}
            className="dynamic-scroll-icon"
          />
          <p className='dynamic-scroll-right-title'>{parse(currentboxRight[1].title)}</p>
          {currentboxRight[1].text.map(e => <p className='dynamic-scroll-text'>{parse(e)}</p>)}
        </div>
      </div>
      <div className='dynamic-scroll-box-right' ref={thirdContainer}>
        <div className='dynamic-scroll-divider-green' style={{height: currentboxRight[2].height}}>
          <img
            alt="dynamic-scroll-circle-green"
            src={`/tec/any_type/circle/${currentboxRight[2].color}.png`}
            className="dynamic-scroll-circle dynamic-scroll-circle-green"
          />
        </div>
        <div className='dynamic-scroll-right-content'>
          <img
            alt="dynamic-scroll-icon"
            src={`/tec/any_type/icon/${currentboxRight[2].icon}.png`}
            className="dynamic-scroll-icon"
          />
          <p className='dynamic-scroll-right-title'>{parse(currentboxRight[2].title)}</p>
          {currentboxRight[2].text.map(e => <p className='dynamic-scroll-text'>{parse(e)}</p>)}
        </div>
      </div>
      <div ref={limitBottom}></div>
    </>
  )
}

export default DynamicScroll
