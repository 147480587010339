import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './tecnologia_tablet.css'

import NavBar from '../../components/navbar'
import VideoPlayer from '../../components/video-player'
import ImageCardTitledTablet from '../../components/tecnologia/tablet/image-card-titled';
import LineImageCard from '../../components/line-img-card'
import NumberedImageCard from '../../components/numbered-img-card'
import CtaTablet from '../../components/common/cta/tablet';
import FooterTablet from '../../components/common/footer/tablet'

const TecnologiaTablet = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  const [showVideo, setShowVideo] = useState(false);
  const videoSource = t("home_video")
  const playVideo = () => setShowVideo(true);

  const imageCardsTitled = [
    {
      img: 'start',
      title: t("technology_text03_01"),
      text: t("technology_text03_02")
    },
    {
      img: 'our',
      title: t("technology_text04_01"),
      text: t("technology_text04_02")
    },
    {
      img: 'ai',
      title: t("technology_text05_01"),
      text: t("technology_text05_02")
    }
  ]

  const lineImage = {
    preferences: {
      tablet: true,
      title: t("technology_text10_01"),
      text: t("technology_tablet_text10_02"),
    },
    cards: [
      {
        img: t("technology_text11_img_01"),
        icon: 'text',
        color: 'orange',
        title: t("technology_text11_01"),
        texts: [
          { text: t("technology_text11_02") },
          { text: t("technology_text11_03") },
        ]
      },
      {
        img: t("technology_text11_img_02"),
        icon: 'image',
        color: 'blue',
        title: t("technology_text11_04"),
        texts: [
          { text: t("technology_text11_05") },
          { text: t("technology_text11_06") },
        ]
      },
      {
        img: t("technology_text11_img_03"),
        icon: 'robot',
        color: 'green',
        title: t("technology_text11_07"),
        texts: [
          { text: t("technology_text11_08") },
          { text: t("technology_text11_09") },
        ]
      },
    ]
  }

  const pasoCards = [
    {
      img: "01",
      number: "01",
      title: t("technology_text13_01"),
      text: t("technology_text13_02")
    },
    {
      img: "02",
      number: "02",
      title: t("technology_text14_01"),
      text: t("technology_text14_02")
    },
    {
      img: "03",
      number: "03",
      title: t("technology_text15_01"),
      text: t("technology_text15_02")
    }
  ]

  return (
    <div className="tec-tablet-container">
      <Helmet>
        <title>Picaioweb</title>
        <meta property="og:title" content="Picaioweb" />
      </Helmet>
      <NavBar />
      {showVideo && <VideoPlayer source={videoSource} width={"90%"} btnMarginRight={"5%"} close={() => setShowVideo(false)} />}
      <section id="inicio" className="tec-tablet-section">
        <div className='tec-tablet-content'>
          <p className='tec-tablet-title'>{parse(t("technology_text01"))}</p>
          <p className='tec-tablet-text'>{parse(t("technology_text02"))}</p>
          <Link onClick={scrollToTop} to="/contacto" className="tec-tablet-btn">
            {t("text_btn_demo")}
            <img
              alt="arrow-right"
              src="/common/arrows/complete/black/right.png"
              className="mobile-menu-arrow-right"
            />
          </Link>
          <button className='tec-tablet-btn-black' onClick={playVideo}>
            {t("text_video")}
            <img
              alt="arrow-right"
              src="/common/arrows/complete/white/right.png"
              className="mobile-menu-arrow-right"
            />
          </button>
        </div>
        <img
          alt="tec-tablet-img"
          src="/tec/magic/Ilustration.svg"
          className="tec-tablet-img"
        />
      </section>
      <section id="cards" className='tec-tablet-cards-section'>
        {imageCardsTitled.map(e => <ImageCardTitledTablet key={e.img} card={e} />)}
      </section>
      <section id="rendimiento" className='tec-tablet-rendimiento-section'>
        <div className='tec-tablet-rendimiento-content'>
          <p className='tec-tablet-rendimiento-title'>{parse(t("technology_text06"))}</p>
          <div className='tec-tablet-rendimiento-row'>
            <div className='tec-tablet-rendimiento-card-01'>
              <p className='tec-tablet-rendimiento-card-title'>99%</p>
              <p className='tec-tablet-rendimiento-card-text' style={{ width: '144px' }}>{parse(t("technology_text07"))}</p>
            </div>
            <div className='tec-tablet-rendimiento-card-divider'></div>
            <div className='tec-tablet-rendimiento-card-02'>
              <p className='tec-tablet-rendimiento-card-title'>{parse(t("technology_text08_01"))}</p>
              <p className='tec-tablet-rendimiento-card-text'>{parse(t("technology_text08_02"))}</p>
            </div>
            <div className='tec-tablet-rendimiento-card-divider'></div>
            <div className='tec-tablet-rendimiento-card-03'>
              <p className='tec-tablet-rendimiento-card-title'>{parse(t("technology_text09_01"))}</p>
              <p className='tec-tablet-rendimiento-card-text'>{parse(t("technology_text09_02"))}</p>
            </div>
          </div>
        </div>
      </section>
      <section id="reconocimiento" className='tec-tablet-reconocimiento-section'>
        <LineImageCard lineImage={lineImage} />
      </section>
      <section id="paso-paso" className='tec-tablet-paso-paso-section'>
        <div className='tec-tablet-paso-paso-content'>
          <p className='tec-tablet-paso-paso-section-title'>{parse(t("technology_text12_01"))}</p>
          <div className='tec-tablet-paso-paso-content-02'>
            <p className='tec-tablet-paso-paso-title'>{parse(t("technology_text12_02"))}</p>
            <p className='tec-tablet-paso-paso-subtitle'>{parse(t("technology_text12_03"))}</p>
          </div>
        </div>
        <div className='tec-tablet-paso-paso-cards-row'>
          {pasoCards.map(e => <NumberedImageCard card={e} />)}
        </div>
      </section>
      <section id='haz-mas' className='tec-tablet-haz-mas-section'>
        <p className='tec-tablet-haz-mas-title'>{parse(t("technology_tablet_text16_01"))}</p>
        <p className='tec-tablet-haz-mas-text'>{parse(t("technology_text16_02"))}</p>
      </section>
      <section id="conciliacion" className='same-tablet-section'>
        <div className='same-tablet-content'>
          <p className='same-tablet-section-title'>{parse(t("technology_tablet_text17_01"))}</p>
          <p className='same-tablet-title'>{parse(t("technology_tablet_text17_02"))}</p>
          <p className='same-tablet-text'>
            {parse(t("technology_tablet_text17_03"))}
            <br />
            <br />
            {parse(t("technology_tablet_text17_04"))}
          </p>
        </div>
        <img
          alt="image"
          src={`/tec/mobile_app/${t("technology_text17_img")}.svg`}
          className="same-tablet-img"
        />
      </section>
      <section id="historial" className='same-tablet-section'>
        <div className='same-tablet-content'>
          <p className='same-tablet-section-title'>{parse(t("technology_tablet_text18_01"))}</p>
          <p className='same-tablet-title'>{parse(t("technology_tablet_text18_02"))}</p>
          <p className='same-tablet-text'>
            {parse(t("technology_tablet_text18_03"))}
            <br />
            <br />
            {parse(t("technology_tablet_text18_04"))}
          </p>
        </div>
        <img
          alt="image"
          src={`/tec/history/${t("technology_text18_img")}.svg`}
          className="same-tablet-img"
        />
      </section>
      <section id="reportes" className='same-tablet-section'>
        <div className='same-tablet-content'>
          <p className='same-tablet-section-title'>{parse(t("technology_tablet_text19_01"))}</p>
          <p className='same-tablet-title'>{parse(t("technology_tablet_text19_02"))}</p>
          <p className='same-tablet-text'>
            {parse(t("technology_tablet_text19_03"))}
            <br />
            <br />
            {parse(t("technology_tablet_text19_04"))}
          </p>
        </div>
        <img
          alt="image"
          src={`/tec/reports/${t("technology_text19_img")}.svg`}
          className="same-tablet-img"
        />
      </section>
      <CtaTablet ctaText={t("cta_tablet_text05")} />
      <FooterTablet />
    </div>
  )
}

export default TecnologiaTablet
