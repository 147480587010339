import React from 'react';
import parse from 'html-react-parser'

import './styles.css';

const PhotoCardDesktop = (props) => {
  const { card } = props;

  return (
    <div className='photo-card-desktop-container'>
      <div className='photo-card-desktop-img-box'>
        <img
          alt="image"
          src={`/who_are_we/our_team/${card.img}.svg`}
          className="photo-card-desktop-img"
        />
      </div>
      <div className='photo-card-desktop-content'>
        <p className='photo-card-desktop-text'>{parse(card.title)}</p>
        <p className='photo-card-desktop-text'>{parse(card.text)}</p>
        <a
          className='photo-card-desktop-text photo-card-desktop-text-highlighted'
          href={card.highlighted}
          target="_blank"
        >
          <u className='photo-card-desktop-text-highlighted'>Linkedin</u>
        </a>
      </div>
    </div>
  )
}

export default PhotoCardDesktop
