import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

import './styles.css';

const FlipCardTablet = (props) => {
  const { flipCard } = props;

  const [isCurrentCard, setIsCurrentCard] = useState(flipCard.isCurrentCard);
  useEffect(() => setIsCurrentCard(flipCard.isCurrentCard), [flipCard.isCurrentCard]);

  const cardClicked = () => props.onCardClicked(flipCard);

  return (
    <div className='flip-card-tablet'>
      <div className='flip-card-tablet-inner' style={isCurrentCard ? { transform: 'rotateY(180deg)' } : {}}>
        <div className='flip-card-tablet-container-front'>
          <img
            alt="image"
            src={`/use_cases/cards/img/${flipCard.img}.svg`}
            className="flip-card-tablet-img"
          />
          <p className='flip-card-tablet-title'>{parse(flipCard.title)}</p>
          <button
            type='button'
            className='flip-card-tablet-button'
            onClick={cardClicked}
          >{flipCard.txtBtn}</button>
        </div>
        <div className='flip-card-tablet-container-back'>
          <div className='flip-card-tablet-icon-row'>
            <img
              alt='card-icon'
              src={`/use_cases/cards/icon/${flipCard.icon}.svg`}
              className='flip-card-tablet-icon'
            />
            <img
              alt='close-icon'
              src={`/use_cases/cards/icon/close.svg`}
              className='flip-card-tablet-close-icon'
              onClick={cardClicked}
            />
          </div>
          <div className='flip-card-tablet-text-row'>
            <p className='flip-card-tablet-title-back'>{parse(flipCard.reverseTitle)}</p>
            <p className='flip-card-tablet-text-back'>{flipCard.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlipCardTablet
