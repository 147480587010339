import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import './aviso_privacidad_tablet.css'

import NavBar from '../../components/navbar'
import FooterTablet from '../../components/common/footer/tablet'

const AvisoPrivacidadTablet = () => {
  const { t } = useTranslation();
  return (
    <div className='aviso-privacidad-tablet-container'>
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <NavBar />
      <section className='aviso-privacidad-tablet-section'>
        <p className='aviso-privacidad-tablet-title'>{t('notice_privacy_text01')}</p>
        <div className='who-are-we-mobile-img-box'>
          <img
            alt="who-are-we-mobile-img"
            src="/terminos_condiciones/tablet/Ilustration.svg"
            className="who-are-we-mobile-img"
          />
        </div>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text02')}</p>
        <p className='aviso-privacidad-tablet-subtitle'>{t('notice_privacy_text03')}</p>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text04')}</p>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text05')}</p>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text06')}</p>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text07')}</p>
        <p className='aviso-privacidad-tablet-subtitle'>{t('notice_privacy_text08')}</p>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text09')}</p>
        <p className='aviso-privacidad-tablet-subtitle'>{t('notice_privacy_text10')}</p>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text11')}</p>
        <p className='aviso-privacidad-tablet-subtitle'>{t('notice_privacy_text12')}</p>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text13')}</p>
        <p className='aviso-privacidad-tablet-subtitle'>{t('notice_privacy_text14')}</p>
        <p className='aviso-privacidad-tablet-text'>{t('notice_privacy_text15')}</p>
      </section>
      <FooterTablet />
    </div>
  )
}

export default AvisoPrivacidadTablet
