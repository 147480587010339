import React, { useState, useEffect } from 'react';

import './styles.css';
import FlipCardDesktop from '../desktop/flip-card-desktop';
import FlipCardTablet from '../tablet/flip-card-tablet';
import FlipCardMobile from '../mobile/flip-card-mobile';

const FlipCardIconRow = (props) => {
  const { preferences, cards } = props.flipCards;

  const [currentCards, setCurrentCards] = useState(cards);
  useEffect(() => setCurrentCards(cards), [cards]);

  const cardClicked = (card) =>
    setCurrentCards(
      currentCards.map(e =>
        e.title === card.title && !card.isCurrentCard ?
          { ...e, isCurrentCard: true } :
          { ...e, isCurrentCard: false }
      )
    );

  return <>
    {
      preferences.isDesktop ?
        <>
          <div className='flip-card-icon-desktop-row'>
            {currentCards.slice(0, 3).map(card =>
              <FlipCardDesktop
                key={card.title}
                flipCard={card}
                onCardClicked={cardClicked}
              />
            )}
          </div>
          <div className='flip-card-icon-desktop-row'>
            {currentCards.slice(3).map(card =>
              <FlipCardDesktop
                key={card.title}
                flipCard={card}
                onCardClicked={cardClicked}
              />
            )}
          </div>
        </>
        : preferences.isTablet ?
          <>
            <div className='flip-card-icon-desktop-row'>
              {currentCards.slice(0, 2).map(card =>
                <FlipCardTablet
                  key={card.title}
                  flipCard={card}
                  onCardClicked={cardClicked}
                />
              )}
            </div>
            <div className='flip-card-icon-desktop-row'>
              {currentCards.slice(2, 4).map(card =>
                <FlipCardTablet
                  key={card.title}
                  flipCard={card}
                  onCardClicked={cardClicked}
                />
              )}
            </div>
            <div className='flip-card-icon-desktop-row'>
              {currentCards.slice(4).map(card =>
                <FlipCardTablet
                  key={card.title}
                  flipCard={card}
                  onCardClicked={cardClicked}
                />
              )}
            </div>
          </>
          :
          <>
            {currentCards.map(card =>
              <FlipCardMobile
                key={card.title}
                flipCard={card}
                onCardClicked={cardClicked}
              />
            )}
          </>
    }
  </>

}

export default FlipCardIconRow
