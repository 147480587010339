import { useMediaQuery } from 'react-responsive'

import TecnologiaDesktop from './tecnologia/tecnologia_desktop'
import TecnologiaTablet from './tecnologia/tecnologia_tablet';
import TecnologiaMobile from './tecnologia/tecnologia_mobile'

const Tecnologia = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return <>
    {isMobile && !isTablet && !isDesktop && <TecnologiaMobile />}
    {isTablet && !isDesktop && <TecnologiaTablet />}
    {isDesktop && <TecnologiaDesktop />}
  </>
}

export default Tecnologia
