import React from 'react';
import parse from 'html-react-parser'

import './styles.css';

const PhotoCardMoblie = (props) => {
  const { card } = props;

  return (
    <div className='photo-card-mobile-container'>
      <img
        alt="image"
        src={`/who_are_we/our_team/${card.img}.svg`}
        className="photo-card-mobile-img"
      />
      <div className='photo-card-mobile-content'>
        <p className='photo-card-mobile-text'>{parse(card.title)}</p>
        <p className='photo-card-mobile-text'>{parse(card.text)}</p>
        <a
          className='photo-card-mobile-text photo-card-mobile-text-highlighted'
          href={card.highlighted}
          target="_blank"
        >
          <u className='photo-card-mobile-text-highlighted'>Linkedin</u>
        </a>
      </div>
    </div>
  )
}

export default PhotoCardMoblie
