import { useMediaQuery } from 'react-responsive'

import CasosUsoDesktop from './casos_uso/casos_uso_desktop';
import CasosUsoTablet from './casos_uso/casos_uso_tablet';
import CasosUsoMobile from './casos_uso/casos_uso_mobile';

const CasosUso = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return <>
    {isMobile && !isTablet && !isDesktop && <CasosUsoMobile />}
    {isTablet && !isDesktop && <CasosUsoTablet />}
    {isDesktop && <CasosUsoDesktop />}
  </>
}

export default CasosUso
