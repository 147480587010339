import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

import './quienes_somos_mobile.css'

import NavBar from '../../components/navbar'
import OurValuesCardMobile from '../../components/quienes_somos/mobile/our-values-card'
import DynamicHeader from '../../components/dynamic-header'
import PhotoCardMoblie from '../../components/quienes_somos/mobile/photo-card'
import CtaMobile from '../../components/common/cta/mobile'
import FooterMobile from '../../components/common/footer/mobile'

const QuienesSomosMobile = () => {
  const { t } = useTranslation();

  const ourValuesCards = [
    {
      img: "geniality",
      title: t("about_us_text04_01"),
      text: t("about_us_mobile_text04_02")
    },
    {
      img: "professionalism",
      title: t("about_us_text05_01"),
      text: t("about_us_mobile_text05_02")
    },
    {
      img: "passion",
      title: t("about_us_text06_01"),
      text: t("about_us_mobile_text06_02")
    },
    {
      img: "proactivity",
      title: t("about_us_text07_01"),
      text: t("about_us_mobile_text07_02")
    },
    {
      img: "reliability",
      title: t("about_us_text08_01"),
      text: t("about_us_mobile_text08_02")
    }
  ]

  const dynamicHeader = {
    isMobile: true,
    titles: [
      t("about_us_text09_02"),
      t("about_us_text09_03"),
      t("about_us_text09_04")
    ]
  }

  const ourTeam = [
    {
      img: "alfredo",
      title: t("about_us_text11_01"),
      text: t("about_us_text11_02"),
      highlighted: "https://www.linkedin.com/in/alfredojcabral/"
    },
    {
      img: "miyeli",
      title: t("about_us_text12_01"),
      text: t("about_us_text12_02"),
      highlighted: "https://www.linkedin.com/in/miyelli-o-76912b184/"
    },
    {
      img: "chepe",
      title: t("about_us_text13_01"),
      text: t("about_us_text13_02"),
      highlighted: "https://www.linkedin.com/in/cheperuiz/"
    },
    {
      img: "carlos",
      title: t("about_us_text14_01"),
      text: t("about_us_text14_02"),
      highlighted: "https://www.linkedin.com/in/carlos-alberto-quintanilla-olmos-7a5590114/"
    },
    {
      img: "mario",
      title: t("about_us_text15_01"),
      text: t("about_us_text15_02"),
      highlighted: "https://www.linkedin.com/in/marioramirezfuentes/"
    },
    {
      img: "laura",
      title: t("about_us_text16_01"),
      text: t("about_us_text16_02"),
      highlighted: "https://www.linkedin.com/in/laura-jimeneg-ui-ux/"
    }
  ]

  return (
    <div className="quienes-somos-mobile-container">
      <Helmet>
        <title>Picaioweb</title>
        <meta property="og:title" content="Picaioweb" />
      </Helmet>
      <NavBar mobile={true} />
      <section
        id="our-mission"
        className="our-mission-mobile-section"
        style={{
          backgroundImage: `url(/quienes-somos/mobile/${t("about_us_bg_01")}.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          height: '700px'
        }}
      >
        <div className='our-mission-mobile-content'>
          <p className='our-mission-mobile-title'>{parse(t("about_us_mobile_text01_01"))}</p>
          <p className='our-mission-mobile-text'>{parse(t("about_us_mobile_text01_02"))}</p>
        </div>
      </section>
      <section id="who-are-we" className="who-are-we-mobile-section">
        <div className='who-are-we-mobile-content'>
          <p className='who-are-we-mobile-title'>{parse(t("about_us_text02_01"))}</p>
          <div className='who-are-we-mobile-img-box'>
            <img
              alt="who-are-we-mobile-img"
              src="/who_are_we/who_are_we/mobile/Ilustration.svg"
              className="who-are-we-mobile-img"
            />
          </div>
          <p className='who-are-we-mobile-text'>
            {parse(t("about_us_mobile_text02_02"))} <b className='who-are-we-mobile-text-highlighted'>{parse(t("about_us_mobile_text02_03"))}</b>
            <br />
            <br />
            {parse(t("about_us_mobile_text02_04"))}
            <br />
            <br />
            <span>{parse(t("about_us_mobile_text02_05"))}{parse(t("about_us_mobile_text02_06"))}</span>
            <br />
            <br />
            {parse(t("about_us_mobile_text02_07"))} <b className='who-are-we-mobile-text-highlighted'>{parse(t("about_us_mobile_text02_08"))}</b>
          </p>
        </div>
      </section>
      <section id="our-values" className='our-values-mobile-section'>
        <div className='our-values-mobile-content'>
          <p className='our-values-mobile-title'>{parse(t("about_us_text03"))}</p>
          <div className='our-values-mobile-cards'>
            {ourValuesCards.map(e => <OurValuesCardMobile card={e} />)}
          </div>
        </div>
      </section>
      <section id="office" className='office-mobile-section'>
        <div className='office-mobile-content'>
          <p className='office-mobile-section-title'>{parse(t("about_us_text09_01"))}</p>
          <DynamicHeader dynamicHeader={dynamicHeader} />
          <p className='office-mobile-text'>{t("about_us_text09_05")}</p>
        </div>
      </section>
      <section id="our-team" className='our-team-mobile-section'>
        <p className='our-team-mobile-title'>{parse(t("about_us_text10_01"))}</p>
        <p className='our-team-mobile-text'>{parse(t("about_us_mobile_text10_02"))}</p>
        <div className='our-team-mobile-cards'>
          {ourTeam.map(e => <PhotoCardMoblie card={e} />)}
        </div>
      </section>
      <CtaMobile ctaText={t("cta_mobile_text06")} />
      <FooterMobile />
    </div>
  )
}

export default QuienesSomosMobile
