import React, { useState, useEffect } from 'react';

import './styles.css';

const DynamicContent = (props) => {
  const { card } = props;

  const [isCurrentCard, setIsCurrentCard] = useState(card.isCurrentCard);
  useEffect(() => setIsCurrentCard(card.isCurrentCard), [card.isCurrentCard]);

  const cardClicked = () => props.onCardClicked(card);

  const cardDeactive = {
    width: "100%",
    height: "auto",
    border: "1px solid #FFFFFF",
    backgroundColor: "#1E1E1E"
  }

  const cardActive = {
    width: "100%",
    height: "auto",
    border: `1px solid ${card.bgc}`,
    backgroundColor: `${card.bgc}`,
  }

  return (
    <div
      className={`dynamiccard ${isCurrentCard ? "dynamiccard-active" : "dynamiccard-deactive"}`}
      style={isCurrentCard ? cardActive : cardDeactive}
      onClick={cardClicked}
    >
      <div className='dynamiccard-frame'>
        <div className='dynamiccard-img-box'>
          <img
            alt="dynamiccard-img"
            src={`/solucion/${card.icon}-${isCurrentCard ? "active" : "deactive"}.svg`}
            className="dynamiccard-img fade-in"
          />
        </div>
        <div className='dynamiccard-text-box'>
          <p
            className={`dynamiccard-text ${isCurrentCard ? "active" : "deactive"} fade-in`}
          >
            {
              !!isCurrentCard ?
                card.description.map(e =>
                  !!e.strong ?
                    <b className='fade-in' style={{ color: '#000000', fontWeight: '700', fontSize: '18px', lineHeight: '28px' }}>{e.text}</b> :
                    <span className='fade-in' style={{ color: '#000000', fontWeight: '400', fontSize: '18px', lineHeight: '28px' }}>{e.text}</span>
                )
                : <strong className='fade-in' style={{ color: '#FFFFFF' }}>{card.title}</strong>
            }
          </p>
        </div>
      </div>
      <div className='dynamiccard-arrow-box'>
        <img
          alt="arrow-img"
          src={`/home/dynamic-card/arrows/arrow_down.svg`}
          className={isCurrentCard ? 'arrow-img rotateOn' : 'arrow-img rotateOff'}
        />
      </div>
    </div>
  )
}

export default DynamicContent
