import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

import './home_mobile.css'

import NavBar from '../../components/navbar'
import WriteText from '../../components/write-text'
import VideoPlayer from '../../components/video-player'
import DynamicCardMobile from '../../components/home/mobile/dynamic-card'
import FuncCardMobile from '../../components/home/mobile/func'
import CtaMobile from '../../components/common/cta/mobile'
import FooterMobile from '../../components/common/footer/mobile'

const HomeMobile = () => {
  const { t } = useTranslation();

  const textsHome = [
    t("home_text01_01"),
    t("home_text01_01"),
    t("home_text01_02")
  ]

  const [showVideo, setShowVideo] = useState(false);
  const videoSource = t("home_video")
  const playVideo = () => setShowVideo(true);

  const textsFeatures = [
    t("home_text24_01"),
    t("home_text24_01"),
    t("home_text24_02")
  ]

  const dynamicCardsAppMobile = {
    preferences: {
      wideImage: false,
      contentToLeft: false,
      title: t('home_text12'),
      subtitle: t('home_text13')
    },
    cards: [
      {
        isCurrentCard: true,
        img: t('home_dynamiccard_mobile_app_01'),
        bgc: "green",
        icon: "app-movil/icono/capture",
        title: t('home_text14_01'),
        description: [
          {
            strong: true,
            text: `${t('home_text14_01')} `
          },
          {
            strong: false,
            text: t('home_text14_02')
          }
        ]
      },
      {
        isCurrentCard: false,
        img: t('home_dynamiccard_mobile_app_02'),
        bgc: "orange",
        icon: "app-movil/icono/extract",
        title: t('home_text15_01'),
        description: [
          {
            strong: false,
            text: t('home_text15_02')
          },
          {
            strong: true,
            text: ` ${t('home_text15_03')} `

          },
          {
            strong: false,
            text: t('home_text15_04')
          }
        ]
      },
      {
        isCurrentCard: false,
        img: t('home_dynamiccard_mobile_app_03'),
        bgc: "blue",
        icon: "app-movil/icono/analize",
        title: t('home_text16_01'),
        description: [
          {
            strong: false,
            text: t('home_text16_02')
          },
          {
            strong: true,
            text: ` ${t('home_text16_03')} `
          },
          {
            strong: false,
            text: t('home_text16_04')
          }
        ]
      },
      {
        isCurrentCard: false,
        img: t('home_dynamiccard_mobile_app_04'),
        bgc: "yellow",
        icon: "app-movil/icono/robot",
        title: t('home_text17_01'),
        description: [
          {
            strong: false,
            text: t('home_text17_02')
          },
          {
            strong: true,
            text: ` ${t('home_text17_03')} `
          },
          {
            strong: false,
            text: t('home_text17_04')
          }
        ]
      }
    ]
  }

  const dynamicCardsWebPlatform = {
    preferences: {
      wideImage: true,
      contentToLeft: true,
      title: t('home_text18'),
      subtitle: t('home_text19')
    },
    cards: [
      {
        isCurrentCard: true,
        img: t('home_dynamiccard_web_platform_tablet_mobile_01'),
        bgc: "green",
        icon: "plataforma-web/icono/load",
        title: t('home_text20_01'),
        description: [
          {
            strong: true,
            text: `${t('home_text20_02')} `
          },
          {
            strong: false,
            text: t('home_text20_03')
          },
          {
            strong: false,
            text: t('home_text20_04')
          }
        ]
      },
      {
        isCurrentCard: false,
        img: t('home_dynamiccard_web_platform_tablet_mobile_02'),
        bgc: "orange",
        icon: "plataforma-web/icono/historia",
        title: t('home_text21_01'),
        description: [
          {
            strong: false,
            text: t('home_text21_02')
          },
          {
            strong: true,
            text: ` ${t('home_text21_03')} `
          },
          {
            strong: false,
            text: t('home_text21_04')
          }
        ]
      },
      {
        isCurrentCard: false,
        img: t('home_dynamiccard_web_platform_tablet_mobile_03'),
        bgc: "blue",
        icon: "plataforma-web/icono/report",
        title: t('home_text22_01'),
        description: [
          {
            strong: false,
            text: t('home_text22_02')
          },
          {
            strong: true,
            text: ` ${t('home_text22_03')} `
          },
          {
            strong: false,
            text: t('home_text22_04')
          }
        ]
      }
    ]
  }

  const funcCards = [
    {
      isActive: true,
      img: "text",
      title: t("home_mobile_text28_01"),
      titleBack: t("home_mobile_back_text28_01"),
      text: t("home_mobile_text28_02")
    },
    {
      isActive: false,
      img: "data",
      title: t("home_mobile_text29_01"),
      titleBack: t("home_mobile_back_text29_01"),
      text: t("home_mobile_text29_02")
    },
    {
      isActive: false,
      img: "doc",
      title: t("home_mobile_text30_01"),
      titleBack: t("home_mobile_back_text30_01"),
      text: t("home_mobile_text30_02")
    },
    {
      isActive: false,
      img: "menu",
      title: t("home_mobile_text31_01"),
      titleBack: t("home_mobile_back_text31_01"),
      text: t("home_mobile_text31_02")
    },
    {
      isActive: false,
      img: "clock",
      title: t("home_mobile_text32_01"),
      titleBack: t("home_mobile_back_text32_01"),
      text: t("home_mobile_text32_02")
    },
    {
      isActive: false,
      img: "square",
      title: t("home_mobile_text33_01"),
      titleBack: t("home_mobile_back_text33_01"),
      text: t("home_mobile_text33_02")
    },
    {
      isActive: false,
      img: "report",
      title: t("home_mobile_text34_01"),
      titleBack: t("home_mobile_back_text34_01"),
      text: t("home_mobile_text34_02")
    }
  ]

  return (
    <div className="home-mobile-container">
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <NavBar mobile={true} />
      {showVideo && <VideoPlayer source={videoSource} width={"90%"} btnMarginRight={"5%"} close={() => setShowVideo(false)} />}
      <section id="tedioso" className="tedioso-mobile-section">
        <div className='tedioso-mobile-content'>
          <p className='tedioso-mobile-title'>
            <WriteText texts={textsHome} />
          </p>
          <p className='tedioso-mobile-subtitle'>{parse(t("home_mobile_text02"))}</p>
          <img
            alt="tedioso-mobile-img"
            src="/home/bill.svg"
            className="tedioso-mobile-img"
          />
          <p className='tedioso-mobile-text'>
            {parse(t("home_mobile_text03_01"))}
            <br />
            <br />
            {parse(t("home_mobile_text03_02"))}
          </p>
        </div>
        <button className='tedioso-mobile-btn' onClick={playVideo}>
          {t("text_video")}
          <img
            alt="arrow-right"
            src="/common/arrows/complete/black/right.png"
            className="mobile-menu-arrow-right"
          />
        </button>
      </section>
      <section id="porcentajes" className="porcentajes-mobile-section">
        <p className='porcentajes-mobile-title'>{parse(t("home_mobile_text05"))}</p>
        <div className='porcentajes-mobile-container'>
          <div className='porcentajes-mobile-box'>
            <p className='porcentajes-mobile-subtitle'>99%</p>
            <p className='porcentajes-mobile-text'>{parse(t("home_text06"))}</p>
          </div>
          <div className='porcentajes-mobile-divider'></div>
          <div className='porcentajes-mobile-box'>
            <p className='porcentajes-mobile-subtitle'>30%</p>
            <p className='porcentajes-mobile-text'>{parse(t("home_text07"))}</p>
          </div>
          <div className='porcentajes-mobile-divider'></div>
          <div className='porcentajes-mobile-box'>
            <p className='porcentajes-mobile-subtitle'>{parse(t("home_text08_01"))}</p>
            <p className='porcentajes-mobile-text'>{parse(t("home_text08_02"))}</p>
          </div>
        </div>
      </section>
      <section id="solucion" className="solucion-mobile-section">
        <div className="solucion-mobile-content">
          <p className='solucion-mobile-section-title'>{parse(t("home_text09_01"))}</p>
          <p className='solucion-mobile-title'>{parse(t("home_mobile_text09_02"))}</p>
        </div>
        <p className='solucion-mobile-text'>
          {parse(t("home_mobile_text10_01"))}
          <br />
          <br />
          {parse(t("home_mobile_text10_02"))}
          <br />
          <br />
          {parse(t("home_mobile_text11_01"))}
          <br />
          <br />
          {parse(t("home_mobile_text11_02"))} <b>{parse(t("home_mobile_text11_03"))}</b>
        </p>
      </section>
      <section id="app-movil" className="app-mobile-section">
        <DynamicCardMobile dynamicCards={dynamicCardsAppMobile} />
      </section>
      <section id="web-platform" className="app-mobile-section">
        <DynamicCardMobile dynamicCards={dynamicCardsWebPlatform} />
      </section>
      <section id='funcionalidades' className='home-fun-mobile-section'>
        <p className='home-fun-mobile-section-title'>{parse(t("home_mobile_text23"))}</p>
        <p className='tedioso-mobile-title'>
          <WriteText texts={textsFeatures} />
        </p>
        <p className='home-fun-mobile-subtitle'>{parse(t("home_mobile_text25"))}</p>
        <p className='home-fun-mobile-text'>
          {parse(t("home_mobile_text26_01"))}
          <span className='home-fun-mobile-text-green'>{parse(t("home_mobile_text26_02"))}</span>
          {parse(t("home_mobile_text26_03"))}
        </p>
        <p className='home-fun-mobile-text'>
          {parse(t("home_mobile_text27_01"))}
        </p>
        <div className='home-fun-mobile-cards-row'>
          <FuncCardMobile cards={funcCards} />
        </div>
      </section>
      <CtaMobile ctaText={t("cta_mobile_text03")} />
      <FooterMobile />
    </div>
  )
}

export default HomeMobile
