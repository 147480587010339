import React from 'react';

import './styles.css';

const Alert = (props) => {

  const { texts, isMobile } = props;

  const emit = () => props.emitAlert();

  return (
    <div className='custom-alert' onClick={emit}>
      <div className={isMobile ?? false ? 'custom-mobile-alert-box' : 'custom-alert-box'}>
        <div className="custom-alert-close-box">
          <img
            alt="custom-alert-close-img"
            src="/common/side-menu/close.png"
            className="custom-alert-close-img"
          />
        </div>
        { texts.invert != true &&
          <img
            alt="custom-alert-img"
            src={`/common/${texts.img}.png`}
            className={texts.invert ?? false ? "custom-alert-close-image" : "custom-alert-close-image-invert"}
            style={{marginBottom: "20px"}}
          />
        }
        <div className='custom-alert-content-box'>
          { texts.invert == true &&
            <img
              alt="custom-alert-img"
              src={`/common/${texts.img}.png`}
              className={texts.invert ?? false ? "custom-alert-close-image" : "custom-alert-close-image-invert"}
            />
          }
          <div className='custom-alert-text-box'>
            <span className='custom-alert-text-bold'>{texts.title}</span>
            <span className='custom-alert-text'>{texts.text}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Alert
