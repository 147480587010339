import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';

import './styles.css';

const WriteText = (props) => {
  let timeToWrite = 125;
  let timeToWait = 1500;
  let wait = false;
  let write = true;
  let wordIndex = 1;
  let index = 0;

  const { texts } = props;

  const [currentTextsToDisplay, setTextsToDisplay] = useState(texts);
  useEffect(() => setTextsToDisplay(texts), [texts]);

  const [currentText, setCurrentText] = useState('');
  const refDynamicText = useRef();

  useEffect(() => {
    let time;

    const interval = setInterval(() => {
      const str = refDynamicText.current?.innerText;

      if (wait) return;

      // delete
      if (str.length > 0 && !write) {
        if (index === 1)
          setCurrentText(`<del>${str.trim().slice(0, -1)}</del>`);
        else
          setCurrentText(str.trim().slice(0, -1));
        return;
      }

      if (str.length === 0 && !write) {
        index = index === 1 ? 2 : 0;
        wordIndex = 1;
        write = true;
        return;
      }

      // span
      if (index === 1) {
        setCurrentText(`<del>${currentTextsToDisplay[index]}</del>`)
        wait = true;
        time = setTimeout(() => {
          write = false;
          wait = false;
        }, timeToWait);
        return;
      }

      // write
      if (wordIndex > currentTextsToDisplay[index].length && write) {
        wait = true;
        time = setTimeout(() => {
          wordIndex = 1;
          index = index === 0 ? 1 : 2;
          if (index === 2) write = false;
          wait = false;
        }, timeToWait);
        return;
      }

      setCurrentText(currentTextsToDisplay[index].substring(0, wordIndex));
      wordIndex = wordIndex + 1;

    }, timeToWrite);
    return () => {
      clearInterval(interval);
      clearTimeout(time)
    };
  }, [currentTextsToDisplay, texts]);

  return <span ref={refDynamicText}>{parse(`${currentText}`)}</span>
}

export default WriteText
