import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

import './home_desktop.css'

import LanguageSelect from '../../components/languageSelect/languageSelect'
import WriteText from '../../components/write-text'
import VideoPlayer from '../../components/video-player'
import DynamicCard from '../../components/home/dynamic-card'
import FuncCard from '../../components/home/func'
import CtaDesktop from '../../components/common/cta/desktop'
import Footer from '../../components/common/footer/desktop'

const HomeDesktop = () => {
	const { t } = useTranslation();

	const scrollToTop = () => window.scrollTo(0, 0);

	const textsHome = [
		t("home_text01_01"),
		t("home_text01_01"),
		t("home_text01_02")
	]

	const [showVideo, setShowVideo] = useState(false);
	const videoSource = t("home_video")
	const playVideo = () => setShowVideo(true);

	const [arrow, setArrow] = useState(false);

	const homeTexts = [
		{
			strong: false,
			text: t("home_text03_01")
		},
		{
			strong: true,
			text: t("home_text03_02")
		}
	]

	const btnVideoArrow = {
		black: "/common/arrows/complete/black/right.png",
		white: "/common/arrows/complete/white/right.png"
	}

	const textsFeatures = [
		t("home_text24_01"),
		t("home_text24_01"),
		t("home_text24_02")
	]

	const dynamicCardsAppMobile = {
		preferences: {
			wideImage: false,
			title: t('home_text12'),
			subtitle: t('home_text13')
		},
		cards: [
			{
				isCurrentCard: true,
				img: t('home_dynamiccard_mobile_app_01'),
				bgc: "#45D7A2",
				icon: "app-movil/icono/capture",
				title: t('home_text14_01'),
				description: [
					{
						strong: true,
						text: t('home_text14_01')
					},
					{
						strong: false,
						text: t('home_text14_02')
					}
				]
			},
			{
				isCurrentCard: false,
				img: t('home_dynamiccard_mobile_app_02'),
				bgc: "#EC632B",
				icon: "app-movil/icono/extract",
				title: t('home_text15_01'),
				description: [
					{
						strong: false,
						text: t('home_text15_02')
					},
					{
						strong: true,
						text: t('home_text15_03')
					},
					{
						strong: false,
						text: t('home_text15_04')
					}
				]
			},
			{
				isCurrentCard: false,
				img: t('home_dynamiccard_mobile_app_03'),
				bgc: "#465DFF",
				icon: "app-movil/icono/analize",
				title: t('home_text16_01'),
				description: [
					{
						strong: false,
						text: t('home_text16_02')
					},
					{
						strong: true,
						text: t('home_text16_03')
					},
					{
						strong: false,
						text: t('home_text16_04')
					}
				]
			},
			{
				isCurrentCard: false,
				img: t('home_dynamiccard_mobile_app_04'),
				bgc: "#FEC40B",
				icon: "app-movil/icono/robot",
				title: t('home_text17_01'),
				description: [
					{
						strong: false,
						text: t('home_text17_02')
					},
					{
						strong: true,
						text: t('home_text17_03')
					},
					{
						strong: false,
						text: t('home_text17_04')
					}
				]
			}
		]
	}

	const dynamicCardsWebPlatform = {
		preferences: {
			wideImage: true,
			title: t('home_text18'),
			subtitle: t('home_text19')
		},
		cards: [
			{
				isCurrentCard: true,
				img: t('home_dynamiccard_web_platform_01'),
				bgc: "#45D7A2",
				icon: "plataforma-web/icono/load",
				title: t('home_text20_01'),
				description: [
					{
						strong: true,
						text: t('home_text20_02')
					},
					{
						strong: false,
						text: t('home_text20_03')
					},
					{
						strong: false,
						text: t('home_text20_04')
					}
				]
			},
			{
				isCurrentCard: false,
				img: t('home_dynamiccard_web_platform_02'),
				bgc: "#EC632B",
				icon: "plataforma-web/icono/historia",
				title: t('home_text21_01'),
				description: [
					{
						strong: false,
						text: t('home_text21_02')
					},
					{
						strong: true,
						text: t('home_text21_03')
					},
					{
						strong: false,
						text: t('home_text21_04')
					}
				]
			},
			{
				isCurrentCard: false,
				img: t('home_dynamiccard_web_platform_03'),
				bgc: "#465DFF",
				icon: "plataforma-web/icono/report",
				title: t('home_text22_01'),
				description: [
					{
						strong: false,
						text: t('home_text22_02')
					},
					{
						strong: true,
						text: t('home_text22_03')
					},
					{
						strong: false,
						text: t('home_text22_04')
					}
				]
			}
		]
	}

	const funcCards = [
		{
			isActive: true,
			img: "text",
			title: t("home_text28_01"),
			titleBack: t("home_back_text28_01"),
			text: t("home_text28_02")
		},
		{
			isActive: false,
			img: "data",
			title: t("home_text29_01"),
			titleBack: t("home_back_text29_01"),
			text: t("home_text29_02")
		},
		{
			isActive: false,
			img: "doc",
			title: t("home_text30_01"),
			titleBack: t("home_back_text30_01"),
			text: t("home_text30_02")
		},
		{
			isActive: false,
			img: "menu",
			title: t("home_text31_01"),
			titleBack: t("home_back_text31_01"),
			text: t("home_text31_02")
		},
		{
			isActive: false,
			img: "clock",
			title: t("home_text32_01"),
			titleBack: t("home_back_text32_01"),
			text: t("home_text32_02")
		},
		{
			isActive: false,
			img: "square",
			title: t("home_text33_01"),
			titleBack: t("home_back_text33_01"),
			text: t("home_text33_02")
		},
		{
			isActive: false,
			img: "report",
			title: t("home_text34_01"),
			titleBack: t("home_back_text34_01"),
			text: t("home_text34_02")
		}
	]

	return (
		<div className="home-container">
			<Helmet>
				<title>Picaio</title>
				<meta property="og:title" content="Picaio" />
			</Helmet>
			<header className="header">
				<div className='header-content'>
					<div className='header-logo'>
						<Link onClick={scrollToTop} to="/" className="header-logo-link">
							<img
								alt="picaio-logo"
								src="/common/header/logo.png"
							/>
						</Link>
					</div>
					<div class='header-nav-list'>
						<div className='header-nav-views'>
							<Link onClick={scrollToTop} to="/" className="header-link header-link-active">{t("nav_text01")}</Link>
							<Link onClick={scrollToTop} to="/casos_uso" className="header-link">{t("nav_text02")}</Link>
							<Link onClick={scrollToTop} to="/tecnologia" className="header-link">{t("nav_text03")}</Link>
							<Link onClick={scrollToTop} to="/quienes_somos" className="header-link">{t("nav_text04")}</Link>
						</div>
						<div className='header-btn-box'>
							<Link onClick={scrollToTop} to="/contacto" className="header-btn">{t("nav_text05")}</Link>
						</div>
						<div className='header-lang-box'><LanguageSelect /></div>
					</div>
				</div>
			</header>
			{showVideo && <VideoPlayer source={videoSource} width={"60%"} btnMarginRight={"20%"} close={() => setShowVideo(false)} />}
			<section id="tedioso" className="tedioso-section">
				<div className='tedioso-content'>
					<div className='tedioso-text-box'>
						<p className='tedioso-title'>
							<WriteText texts={textsHome} />
						</p>
						<p className='tedioso-subtitle'>{parse(t("home_text02"))}</p>
						<p className='tedioso-text'>
							{homeTexts.map(e =>
								e.strong ?
									<strong>{parse(e.text)}</strong>
									:
									<span>{parse(e.text)}</span>
							)}
						</p>
						<p className='tedioso-text'>{parse(t("home_text03_03"))}</p>
					</div>
					<div className='tedioso-btn-box'>
						<button
							className='tedioso-btn'
							onClick={playVideo}
							onMouseEnter={() => setArrow(true)}
							onMouseLeave={() => setArrow(false)}
						>
							{t("text_video")}
							<img
								alt="arrow-right"
								src={arrow ? btnVideoArrow.white : btnVideoArrow.black}
								className="mobile-menu-arrow-right"
							/>
						</button>
					</div>
				</div>
				<div className='tedioso-img-box'>
					<img
						alt="tedioso-img"
						src={`solucion/tedioso/${t("home_image_01")}.svg`}
						className="tedioso-img fade-in"
					/>
				</div>
			</section>
			<section id="porcentajes" className="porcentajes-section">
				<p className='porcentajes-title'>{parse(t("home_text05"))}</p>
				<div className='porcentajes-row'>
					<div className='porcentajes-stat'>
						<p className='porcentajes-subtitle'>99%</p>
						<p className='porcentajes-text'>{parse(`${t("home_text06")}`)}</p>
					</div>
					<div className='porcentajes-divider'></div>
					<div className='porcentajes-stat'>
						<p className='porcentajes-subtitle'>30%</p>
						<p className='porcentajes-text'>{parse(`${t("home_text07")}`)}</p>
					</div>
					<div className='porcentajes-divider'></div>
					<div className='porcentajes-stat'>
						<p className='porcentajes-subtitle'>{t("home_text08_01")}</p>
						<p className='porcentajes-text'>{parse(`${t("home_text08_02")}`)}</p>
					</div>
				</div>
			</section>
			<section id="solucion" className="solucion-section">
				<div className='solucion-title-box'>
					<p className='solucion-section-title'>{parse(t("home_text09_01"))}</p>
					<p className='solucion-title'>{parse(t("home_text09_02"))}</p>
				</div>
				<div className='solucion-text-box'>
					<div className='solucion-column'>
						<p className='solucion-text'>
							{parse(t("home_text10_01"))}
							<br />
							<br />
							{parse(t("home_text10_02"))}
						</p>
					</div>
					<div className='solucion-column'>
						<p className='solucion-text'>
							{parse(t("home_text11_01"))}
							<br />
							<br />
							{parse(t("home_text11_02"))} <b>{parse(t("home_text11_03"))}</b>
						</p>
					</div>
				</div>
			</section>
			<section id="app-movil" className="app-section">
				<DynamicCard dynamicCards={dynamicCardsAppMobile} />
			</section>
			<section id="web-platform" className="app-section-02">
				<DynamicCard dynamicCards={dynamicCardsWebPlatform} />
			</section>
			<section id='funcionalidades' className='home-fun-section'>
				<div className='home-fun-text-box-container'>
					<div className='home-fun-titles'>
						<p className='home-fun-section-title'>{parse(t("home_text23"))}</p>
						<div className='home-fun-title-box'>
							<p className='tedioso-title'>
								<WriteText texts={textsFeatures} />
							</p>
						</div>
					</div>
					<div className='home-fun-text-box'>
						<p className='home-fun-subtitle'>{parse(t("home_text25"))}</p>
						<div className='home-fun-columns-box'>
							<p className='home-fun-column-text-01'>
								{parse(t("home_text26_01"))} <span className='home-fun-column-text-green'>{parse(t("home_text26_02"))}</span> {parse(t("home_text26_03"))}
							</p>
							<p className='home-fun-column-text-02'>{parse(t("home_text27_01"))}</p>
						</div>
					</div>
				</div>
				<div className='home-fun-cards-row'>
					<FuncCard cards={funcCards} />
				</div>
			</section>
			<CtaDesktop ctaText={t("cta_text03")} />
			<Footer />
		</div>
	)
}

export default HomeDesktop
