import React from 'react'
import { useMediaQuery } from 'react-responsive'

import ContactoDesktop from './contacto/contacto_desktop'
import ContactoTablet from './contacto/contacto_tablet'
import ContactoMobile from './contacto/contacto_mobile'

const Contacto = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return <>
    {isMobile && !isTablet && !isDesktop && <ContactoMobile />}
    {isTablet && !isDesktop && <ContactoTablet />}
    {isDesktop && <ContactoDesktop />}
  </>
}

export default Contacto
