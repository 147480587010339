import React from 'react';
import parse from 'html-react-parser';

import './styles.css';

const NumberedImageCardMobile = (props) => {
  const { card } = props;

  return (
    <div className='numbered-img-card-mobile-container'>
      <img
        alt="image"
        src={`/tec/how/${card.img}.svg`}
        className="numbered-img-card-mobile-img"
      />
      <div className='numbered-img-card-mobile-text-box'>
        <div className='numbered-img-card-mobile-title-box'>
          <span className='numbered-img-card-mobile-number-text'>{card.number}</span>
          <span className='numbered-img-card-mobile-title'> {parse(card.title)}</span>
        </div>
        <p className='numbered-img-card-mobile-text'>{parse(card.text)}</p>
      </div>
    </div>
  )
}

export default NumberedImageCardMobile
