import React, { useState, useEffect } from 'react';

import './styles.css';

const VideoPlayer = (props) => {
  const { source, width, btnMarginRight } = props;

  const [currentSource, setCurrentSource] = useState(source);
  useEffect(() => setCurrentSource(source), [source]);

  const onClose = () => props.close();

  return (
    <div className='video-backdrop'>
      <div className='video-container'>
        <img
          onClick={onClose}
          alt="video-close-icon"
          src="/common/side-menu/close.png"
          className="video-close-icon"
          style={{ marginRight: `${btnMarginRight}` }}
        />
        <video width={width} controls autoplay>
          <source src={`/video/${currentSource}.mp4`} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    </div>
  )
}

export default VideoPlayer
