import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import './terminos_condiciones_desktop.css'

import LanguageSelect from '../../components/languageSelect/languageSelect'
import Footer from '../../components/common/footer/desktop'

const TerminosCondicionesDesktop = () => {

  const { t } = useTranslation();
  const scrollToTop = () => window.scrollTo(0, 0);

  return (
    <div className="terminos-condiciones-container">
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <header className="header">
        <div className='header-content'>
          <div className='header-logo'>
            <Link onClick={scrollToTop} to="/" className="header-logo-link">
              <img
                alt="picaio-logo"
                src="/common/header/logo.png"
              />
            </Link>
          </div>
          <div class='header-nav-list'>
            <div className='header-nav-views'>
              <Link onClick={scrollToTop} to="/" className="header-link">{t("nav_text01")}</Link>
              <Link onClick={scrollToTop} to="/casos_uso" className="header-link">{t("nav_text02")}</Link>
              <Link onClick={scrollToTop} to="/tecnologia" className="header-link">{t("nav_text03")}</Link>
              <Link onClick={scrollToTop} to="/quienes_somos" className="header-link">{t("nav_text04")}</Link>
            </div>
            <div className='header-btn-box'>
              <Link onClick={scrollToTop} to="/contacto" className="header-btn">{t("nav_text05")}</Link>
            </div>
            <div className='header-lang-box'><LanguageSelect /></div>
          </div>
        </div>
      </header>
      <section className='terminos-condiciones-section'>
        <p className='terminos-condiciones-title'>{t('condicion_text01')}</p>
        <img
          alt="terminos-condiciones-image"
          src="/who_are_we/who_are_we/Ilustration.svg"
          className="terminos-condiciones-image"
        />
        <p className='terminos-condiciones-text'>{t('condicion_text02')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text03')}</p>
        <p className='terminos-condiciones-self-start'>
          <span className='terminos-condiciones-subtitle'>{t('condicion_text04')}</span>
          <span className='terminos-condiciones-text'>{t('condicion_text05')}</span>
        </p>
        <p className='terminos-condiciones-self-start'>
          <span className='terminos-condiciones-subtitle'>{t('condicion_text06')}</span>
          <span className='terminos-condiciones-text'>{t('condicion_text07')}</span>
        </p>
        <p className='terminos-condiciones-self-start'>
          <span className='terminos-condiciones-subtitle'>{t('condicion_text08')}</span>
          <span className='terminos-condiciones-text'>{t('condicion_text09')}</span>
        </p>
        <p className='terminos-condiciones-self-start'>
          <span className='terminos-condiciones-subtitle'>{t('condicion_text10')}</span>
          <span className='terminos-condiciones-text'>{t('condicion_text11')}</span>
        </p>
        <p className='terminos-condiciones-self-start'>
          <span className='terminos-condiciones-subtitle'>{t('condicion_text12')}</span>
          <span className='terminos-condiciones-text'>{t('condicion_text13')}</span>
        </p>
        <p className='terminos-condiciones-self-start'>
          <span className='terminos-condiciones-subtitle'>{t('condicion_text14')}</span>
          <span className='terminos-condiciones-text'>{t('condicion_text15')}</span>
        </p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text16')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text17')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text18')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text19')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text20')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text21')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text22')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text23')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text24')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text25')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text26')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text27')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text28')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text29')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text30')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text31')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text32')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text33')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text34')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text35')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text36')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text37')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text38')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text39')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text40')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text41')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text42')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text43')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text44')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text45')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text46')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text47')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text48')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text49')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text50')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text51')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text52')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text53')}</p>
        <p className='terminos-condiciones-subtitle'>{t('condicion_text54')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text55')}</p>
        <p className='terminos-condiciones-text'>{t('condicion_text56')}</p>
      </section>
      <Footer />
    </div>
  )
}

export default TerminosCondicionesDesktop
