import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './tecnologia_mobile.css'

import NavBar from '../../components/navbar'
import VideoPlayer from '../../components/video-player'
import ImageCardTitledMobile from '../../components/tecnologia/mobile/image-card-titled';
import LineImageCard from '../../components/line-img-card'
import NumberedImageCardMobile from '../../components/tecnologia/mobile/numbered-image-card';
import CtaMobile from '../../components/common/cta/mobile'
import FooterMobile from '../../components/common/footer/mobile'

const TecnologiaMobile = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  const [showVideo, setShowVideo] = useState(false);
  const videoSource = t("home_video")
  const playVideo = () => setShowVideo(true);

  const imageCardsTitled = [
    {
      img: 'start',
      title: t("technology_mobile_text03_01"),
      text: t("technology_mobile_text03_02")
    },
    {
      img: 'our',
      title: t("technology_mobile_text04_01"),
      text: t("technology_mobile_text04_02")
    },
    {
      img: 'ai',
      title: t("technology_mobile_text05_01"),
      text: t("technology_mobile_text05_02")
    }
  ]

  const lineImage = {
    preferences: {
      title: t("technology_text10_01"),
      text: t("technology_mobile_text10_02"),
    },
    cards: [
      {
        img: t("technology_text11_img_01"),
        icon: 'text',
        color: 'orange',
        title: t("technology_mobile_text11_01"),
        texts: [
          { text: t("technology_mobile_text11_02") },
          { text: t("technology_mobile_text11_03") },
        ]
      },
      {
        img: t("technology_text11_img_02"),
        icon: 'image',
        color: 'blue',
        title: t("technology_mobile_text11_04"),
        texts: [
          { text: t("technology_mobile_text11_05") },
          { text: t("technology_mobile_text11_06") },
        ]
      },
      {
        img: t("technology_text11_img_03"),
        icon: 'robot',
        color: 'green',
        title: t("technology_mobile_text11_07"),
        texts: [
          { text: t("technology_mobile_text11_08") },
          { text: t("technology_mobile_text11_09") },
        ]
      },
    ]
  }

  const pasoCards = [
    {
      img: "01",
      number: "01",
      title: t("technology_mobile_text13_01"),
      text: t("technology_mobile_text13_02")
    },
    {
      img: "02",
      number: "02",
      title: t("technology_mobile_text14_01"),
      text: t("technology_mobile_text14_02")
    },
    {
      img: "03",
      number: "03",
      title: t("technology_mobile_text15_01"),
      text: t("technology_mobile_text15_02")
    }
  ]

  return (
    <div className="tec-mobile-container">
      <Helmet>
        <title>Picaioweb</title>
        <meta property="og:title" content="Picaioweb" />
      </Helmet>
      <NavBar mobile={true} />
      {showVideo && <VideoPlayer source={videoSource} width={"90%"} btnMarginRight={"5%"} close={() => setShowVideo(false)} />}
      <section id="inicio" className="tec-mobile-section">
        <div className='tec-mobile-content'>
          <p className='tec-mobile-title'>{parse(t("technology_mobile_text01"))}</p>
          <p className='tec-mobile-text'>{parse(t("technology_mobile_text02"))}</p>
          <img
            alt="tec-mobile-img"
            src="/tec/magic/Ilustration.svg"
            className="tec-mobile-img"
          />
        </div>
        <Link onClick={scrollToTop} to="/contacto" className="tec-mobile-btn">
          {t("text_btn_demo")}
          <img
            alt="arrow-right"
            src="/common/arrows/complete/black/right.png"
            className="mobile-menu-arrow-right"
          />
        </Link>
        <button className='tec-mobile-btn-black' onClick={playVideo}>
          {t("text_video")}
          <img
            alt="arrow-right"
            src="/common/arrows/complete/white/right.png"
            className="mobile-menu-arrow-right"
          />
        </button>
      </section>
      <section id="cards" className='tec-mobile-cards-section'>
        {imageCardsTitled.map(e => <ImageCardTitledMobile key={e.img} card={e} />)}
      </section>
      <section id="rendimiento" className='tec-mobile-rendimiento-section'>
        <p className='tec-mobile-rendimiento-title'>{parse(t("technology_mobile_text06"))}</p>
        <div className='tec-mobile-rendimiento-row'>
          <div className='tec-mobile-rendimiento-card'>
            <p className='tec-mobile-rendimiento-card-title'>99%</p>
            <p className='tec-mobile-rendimiento-card-text'>{parse(t("technology_text07"))}</p>
          </div>
          <div className='tec-mobile-rendimiento-card-divider'></div>
          <div className='tec-mobile-rendimiento-card'>
            <p className='tec-mobile-rendimiento-card-title'>{parse(t("technology_text08_01"))}</p>
            <p className='tec-mobile-rendimiento-card-text'>{parse(t("technology_text08_02"))}</p>
          </div>
          <div className='tec-mobile-rendimiento-card-divider'></div>
          <div className='tec-mobile-rendimiento-card'>
            <p className='tec-mobile-rendimiento-card-title'>{parse(t("technology_text09_01"))}</p>
            <p className='tec-mobile-rendimiento-card-text'>{parse(t("technology_text09_02"))}</p>
          </div>
        </div>
      </section>
      <section id="reconocimiento" className='tec-mobile-reconocimiento-section'>
        <LineImageCard lineImage={lineImage} />
      </section>
      <section id="paso-paso" className='tec-mobile-paso-paso-section'>
        <div className='tec-mobile-paso-paso-content'>
          <p className='tec-mobile-paso-paso-section-title'>{parse(t("technology_text12_01"))}</p>
          <div className='tec-mobile-paso-paso-title-box'>
            <p className='tec-mobile-paso-paso-title'>{parse(t("technology_text12_02"))}</p>
            <p className='tec-mobile-paso-paso-subtitle'>{parse(t("technology_text12_03"))}</p>
          </div>
        </div>
        <div className='tec-mobile-paso-paso-cards-row'>
          {pasoCards.map(e => <NumberedImageCardMobile card={e} />)}
        </div>
      </section>
      <section id='haz-mas' className='tec-mobile-haz-mas-section'>
        <p className='tec-mobile-haz-mas-title'>{parse(t("technology_mobile_text16_01"))}</p>
        <p className='tec-mobile-haz-mas-text'>{parse(t("technology_mobile_text16_02"))}</p>
      </section>
      <section id="conciliacion" className='same-mobile-section'>
        <div className='same-mobile-content'>
          <p className='same-mobile-section-title'>{parse(t("technology_mobile_text17_01"))}</p>
          <p className='same-mobile-title'>{parse(t("technology_mobile_text17_02"))}</p>
          <p className='same-mobile-text'>
            {parse(t("technology_mobile_text17_03"))}
            <br />
            <br />
            {parse(t("technology_mobile_text17_04"))}
          </p>
        </div>
        <img
          alt="image"
          src={`/tec/mobile_app/${t("technology_text17_img")}.svg`}
          className="same-mobile-img"
        />
      </section>
      <section id="historial" className='same-mobile-section'>
        <div className='same-mobile-content'>
          <p className='same-mobile-section-title'>{parse(t("technology_mobile_text18_01"))}</p>
          <p className='same-mobile-title'>{parse(t("technology_mobile_text18_02"))}</p>
          <p className='same-mobile-text'>
            {parse(t("technology_mobile_text18_03"))}
            <br />
            <br />
            {parse(t("technology_mobile_text18_04"))}
          </p>
        </div>
        <img
          alt="image"
          src={`/tec/history/${t("technology_text18_img")}.svg`}
          className="same-mobile-img"
        />
      </section>
      <section id="reportes" className='same-mobile-section'>
        <div className='same-mobile-content'>
          <p className='same-mobile-section-title'>{parse(t("technology_mobile_text19_01"))}</p>
          <p className='same-mobile-title'>{parse(t("technology_mobile_text19_02"))}</p>
          <p className='same-mobile-text'>
            {parse(t("technology_mobile_text19_03"))}
            <br />
            <br />
            {parse(t("technology_mobile_text19_04"))}
          </p>
        </div>
        <img
          alt="image"
          src={`/tec/reports/${t("technology_text19_img")}.svg`}
          className="same-mobile-img"
        />
      </section>
      <CtaMobile ctaText={t("cta_mobile_text05")} />
      <FooterMobile />
    </div>
  )
}

export default TecnologiaMobile
