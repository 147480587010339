import React from 'react';
import parse from 'html-react-parser'

import './styles.css';

const OurValuesCardDesktop = (props) => {
  const { card } = props;

  return (
    <div className='our-values-card-container'>
      <img
        alt="image"
        src={`/who_are_we/our_values/${card.img}.svg`}
        className="our-values-card-img"
      />
      <div className='our-values-card-content'>
        <p className='our-values-card-title'>{parse(card.title)}</p>
        <p className='our-values-card-text'>{parse(card.text)}</p>
      </div>
    </div>
  )
}

export default OurValuesCardDesktop
