import React from 'react';
import parse from 'html-react-parser';

import './styles.css';

const NumberedImageCardDesktop = (props) => {
  const { card } = props;

  return <>
    <div className='numbered-image-card-desktop-container'>
      <img
        alt="image"
        src={`/tec/how/${card.img}.svg`}
        className="numbered-image-card-desktop-image"
      />
      <div className='numbered-image-card-desktop-text-box'>
        <p className='numbered-image-card-desktop-title'>
          <span className='numbered-image-card-desktop-number-text'>{card.number}</span>
          <span className='numbered-image-card-desktop-title'>{parse(card.title)}</span>
        </p>
        <p className='numbered-image-card-desktop-text'>{parse(card.text)}</p>
      </div>
    </div>
  </>
}

export default NumberedImageCardDesktop
