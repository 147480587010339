import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './styles.css';

import LanguageSelect from '../../../languageSelect/languageSelect';

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  return (
    <footer className="footer-section">
      <div className='footer-content'>
        <div className='footer-img-box'>
          <img
            alt="picaio-logo"
            src="/common/header/logo.png"
            className="footer-logo"
          />
        </div>
        <div className='footer-pages'>
          <Link onClick={scrollToTop} to="/" className="footer-link">{t("nav_text01")}</Link>
          <Link onClick={scrollToTop} to="/casos_uso" className="footer-link">{t("nav_text02")}</Link>
          <Link onClick={scrollToTop} to="/tecnologia" className="footer-link">{t("nav_text03")}</Link>
          <Link onClick={scrollToTop} to="/quienes_somos" className="footer-link">{t("nav_text04")}</Link>
        </div>
        <div className='footer-lang-box'>
          <LanguageSelect />
        </div>
        <div className='footer-social-box'>
          <a href='https://www.linkedin.com/company/picaioai' target="_blank">
            <img
              alt="picaio-socal-logo"
              src="/footer/social.png"
              className="footer-social-img"
            />
          </a>
        </div>
      </div>
      <div className='footer-copyright-box'>
        <div className='footer-divider'></div>
        <p className='footer-copyright-text'>
          <span>{t("footer_text01")}</span>
          <Link onClick={scrollToTop} to="/terminos-condiciones"><span>{t("footer_text02")}</span></Link>
          <span>{t("footer_text03")}</span>
          <Link onClick={scrollToTop} to="/aviso-privacidad"><span>{t("footer_text04")}</span></Link>
        </p>
      </div>
    </footer>
  )
}

export default Footer
