import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './quienes_somos_tablet.css'

import NavBar from '../../components/navbar'
import OurValuesCardTablet from '../../components/quienes_somos/tablet/our-values-card'
import DynamicHeader from '../../components/dynamic-header'
import PhotoCardTablet from '../../components/quienes_somos/tablet/photo-card'
import CtaTablet from '../../components/common/cta/tablet'
import FooterTablet from '../../components/common/footer/tablet'

const QuienesSomosTablet = () => {
  const { t } = useTranslation();

  const ourValuesCards = [
    {
      img: "geniality",
      title: t("about_us_text04_01"),
      text: t("about_us_tablet_text04_02")
    },
    {
      img: "professionalism",
      title: t("about_us_text05_01"),
      text: t("about_us_tablet_text05_02")
    },
    {
      img: "passion",
      title: t("about_us_text06_01"),
      text: t("about_us_tablet_text06_02")
    },
    {
      img: "proactivity",
      title: t("about_us_text07_01"),
      text: t("about_us_tablet_text07_02")
    },
    {
      img: "reliability",
      title: t("about_us_text08_01"),
      text: t("about_us_tablet_text08_02")
    }
  ]

  const dynamicHeader = {
    isMobile: true,
    titles: [
      t("about_us_text09_02"),
      t("about_us_text09_03"),
      t("about_us_text09_04")
    ]
  }

  const ourTeam = [
    {
      img: "alfredo",
      title: t("about_us_text11_01"),
      text: t("about_us_text11_02"),
      highlighted: "https://www.linkedin.com/in/alfredojcabral/"
    },
    {
      img: "miyeli",
      title: t("about_us_text12_01"),
      text: t("about_us_text12_02"),
      highlighted: "https://www.linkedin.com/in/miyelli-o-76912b184/"
    },
    {
      img: "chepe",
      title: t("about_us_text13_01"),
      text: t("about_us_text13_02"),
      highlighted: "https://www.linkedin.com/in/cheperuiz/"
    },
    {
      img: "carlos",
      title: t("about_us_text14_01"),
      text: t("about_us_text14_02"),
      highlighted: "https://www.linkedin.com/in/carlos-alberto-quintanilla-olmos-7a5590114/"
    },
    {
      img: "mario",
      title: t("about_us_text15_01"),
      text: t("about_us_text15_02"),
      highlighted: "https://www.linkedin.com/in/marioramirezfuentes/"
    },
    {
      img: "laura",
      title: t("about_us_text16_01"),
      text: t("about_us_text16_02"),
      highlighted: "https://www.linkedin.com/in/laura-jimeneg-ui-ux/"
    }
  ]

  return (
    <div className="quienes-somos-tablet-container">
      <Helmet>
        <title>Picaioweb</title>
        <meta property="og:title" content="Picaioweb" />
      </Helmet>
      <NavBar />
      <section
        id="our-mission"
        className="our-mission-tablet-section"
        style={{
          backgroundImage: `url(/quienes-somos/tablet/${t("about_us_bg_01")}.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          height: '700px'
        }}
      >
        <div className='our-mission-tablet-content'>
          <p className='our-mission-tablet-title'>{parse(t("about_us_tablet_text01_01"))}</p>
          <p className='our-mission-tablet-text'>{parse(t("about_us_tablet_text01_02"))}</p>
        </div>
      </section>
      <section id="who-are-we" className="who-are-we-tablet-section">
        <p className='who-are-we-tablet-title'>{parse(t("about_us_text02_01"))}</p>
        <img
          alt="who-are-we-tablet-img"
          src="/terminos_condiciones/tablet/Ilustration.svg"
          className="who-are-we-tablet-img"
        />
        <p className='who-are-we-tablet-text'>
          {parse(t("about_us_tablet_text02_02"))} <b className='who-are-we-tablet-text-highlighted'>{parse(t("about_us_tablet_text02_03"))}</b>
          <br />
          <br />
          {parse(t("about_us_tablet_text02_04"))}
          <br />
          <br />
          <span>{parse(t("about_us_tablet_text02_05"))}{parse(t("about_us_tablet_text02_06"))}</span>
          <br />
          <br />
          {parse(t("about_us_tablet_text02_07"))} <b className='who-are-we-tablet-text-highlighted'>{parse(t("about_us_tablet_text02_08"))}</b>
        </p>
      </section>
      <section id="our-values" className='our-values-tablet-section'>
        <div className='our-values-tablet-content'>
          <p className='our-values-tablet-title'>{t("about_us_text03")}</p>
          <div className='our-values-tablet-cards'>
            <div className='our-values-tablet-cards-row'>
              {ourValuesCards.slice(0, 2).map(e => <OurValuesCardTablet card={e} />)}
            </div>
            <div className='our-values-tablet-cards-row'>
              {ourValuesCards.slice(2, 4).map(e => <OurValuesCardTablet card={e} />)}
            </div>
            <div className='our-values-tablet-cards-row'>
              {ourValuesCards.slice(4).map(e => <OurValuesCardTablet card={e} />)}
            </div>
          </div>
        </div>
      </section>
      <section id="office" className='office-tablet-section'>
        <div className='office-tablet-content'>
          <p className='office-tablet-section-title'>{parse(t("about_us_text09_01"))}</p>
          <DynamicHeader dynamicHeader={dynamicHeader} />
          <p className='office-tablet-text'>{parse(t("about_us_text09_05"))}</p>
        </div>
      </section>
      <section id="our-team" className='our-team-tablet-section'>
        <div className='our-team-tablet-content'>
          <p className='our-team-tablet-title'>{parse(t("about_us_text10_01"))}</p>
          <p className='our-team-tablet-text'>{parse(t("about_us_tablet_text10_02"))}</p>
          <div className='our-team-tablet-cards'>
            <div className='our-team-tablet-cards-row'>
              {ourTeam.slice(0, 2).map(e => <PhotoCardTablet card={e} />)}
            </div>
            <div className='our-team-tablet-cards-row'>
              {ourTeam.slice(2, 4).map(e => <PhotoCardTablet card={e} />)}
            </div>
            <div className='our-team-tablet-cards-row'>
              {ourTeam.slice(4).map(e => <PhotoCardTablet card={e} />)}
            </div>
          </div>
        </div>
      </section>
      <CtaTablet ctaText={t("cta_text06")} />
      <FooterTablet />
    </div>
  )
}

export default QuienesSomosTablet
