import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

import './home_tablet.css'

import WriteText from '../../components/write-text'
import VideoPlayer from '../../components/video-player'
import NavBar from '../../components/navbar'
import DynamicCardTablet from '../../components/home/tablet/dynamic-card'
import FuncCardTablet from '../../components/home/tablet/func-card'
import CtaTablet from '../../components/common/cta/tablet'
import FooterTablet from '../../components/common/footer/tablet'

const HomeTablet = () => {
  const { t } = useTranslation();

  const textsHome = [
    t("home_text01_01"),
    t("home_text01_01"),
    t("home_text01_02")
  ]

  const [showVideo, setShowVideo] = useState(false);
  const videoSource = t("home_video")
  const playVideo = () => setShowVideo(true);

  const [arrow, setArrow] = useState(false);
  const btnVideoArrow = {
    black: "/common/arrows/complete/black/right.png",
    white: "/common/arrows/complete/white/right.png"
  }

  const textsFeatures = [
    t("home_text24_01"),
    t("home_text24_01"),
    t("home_text24_02")
  ]

  const dynamicCardsAppMobile = {
    preferences: {
      isTablet: true,
      wideImage: false,
      contentToLeft: false,
      title: t('home_text12'),
      subtitle: t('home_text13')
    },
    cards: [
      {
        isCurrentCard: true,
        isTablet: true,
        img: t('home_dynamiccard_mobile_app_01'),
        bgc: "green",
        icon: "app-movil/icono/capture",
        title: t('home_text14_01'),
        description: [
          {
            strong: true,
            text: t('home_text14_01')
          },
          {
            strong: false,
            text: t('home_text14_02')
          }
        ]
      },
      {
        isCurrentCard: false,
        isTablet: true,
        img: t('home_dynamiccard_mobile_app_02'),
        bgc: "orange",
        icon: "app-movil/icono/extract",
        title: t('home_text15_01'),
        description: [
          {
            strong: false,
            text: t('home_text15_02')
          },
          {
            strong: true,
            text: t('home_text15_03')

          },
          {
            strong: false,
            text: t('home_text15_04')
          }
        ]
      },
      {
        isCurrentCard: false,
        isTablet: true,
        img: t('home_dynamiccard_mobile_app_03'),
        bgc: "blue",
        icon: "app-movil/icono/analize",
        title: t('home_text16_01'),
        description: [
          {
            strong: false,
            text: t('home_text16_02')
          },
          {
            strong: true,
            text: t('home_text16_03')
          },
          {
            strong: false,
            text: t('home_text16_04')
          }
        ]
      },
      {
        isCurrentCard: false,
        isTablet: true,
        img: t('home_dynamiccard_mobile_app_04'),
        bgc: "yellow",
        icon: "app-movil/icono/robot",
        title: t('home_text17_01'),
        description: [
          {
            strong: false,
            text: t('home_text17_02')
          },
          {
            strong: true,
            text: t('home_text17_03')
          },
          {
            strong: false,
            text: t('home_text17_04')
          }
        ]
      }
    ]
  }

  const dynamicCardsWebPlatform = {
    preferences: {
      isTablet: true,
      wideImage: true,
      contentToLeft: true,
      title: t('home_text18'),
      subtitle: t('home_text19')
    },
    cards: [
      {
        isCurrentCard: true,
        isTablet: true,
        img: t('home_dynamiccard_web_platform_tablet_mobile_01'),
        width: '680px',
        bgc: "green",
        icon: "plataforma-web/icono/load",
        title: t('home_text20_01'),
        description: [
          {
            strong: true,
            text: t('home_text20_02')
          },
          {
            strong: false,
            text: t('home_text20_03')
          },
          {
            strong: false,
            text: t('home_text20_04')
          }
        ]
      },
      {
        isCurrentCard: false,
        isTablet: true,
        img: t('home_dynamiccard_web_platform_tablet_mobile_02'),
        width: '680px',
        bgc: "orange",
        icon: "plataforma-web/icono/historia",
        title: t('home_text21_01'),
        description: [
          {
            strong: false,
            text: t('home_text21_02')
          },
          {
            strong: true,
            text: t('home_text21_03')
          },
          {
            strong: false,
            text: t('home_text21_04')
          }
        ]
      },
      {
        isCurrentCard: false,
        isTablet: true,
        img: t('home_dynamiccard_web_platform_tablet_mobile_03'),
        width: '680px',
        bgc: "blue",
        icon: "plataforma-web/icono/report",
        title: t('home_text22_01'),
        description: [
          {
            strong: false,
            text: t('home_text22_02')
          },
          {
            strong: true,
            text: t('home_text22_03')
          },
          {
            strong: false,
            text: t('home_text22_04')
          }
        ]
      }
    ]
  }

  const funcCards = [
    {
      isActive: true,
      img: "text",
      title: t("home_tablet_text28_01"),
      titleBack: t("home_tablet_back_text28_01"),
      text: t("home_tablet_text28_02")
    },
    {
      isActive: false,
      img: "data",
      title: t("home_tablet_text29_01"),
      titleBack: t("home_tablet_back_text29_01"),
      text: t("home_tablet_text29_02")
    },
    {
      isActive: false,
      img: "doc",
      title: t("home_tablet_text30_01"),
      titleBack: t("home_tablet_back_text30_01"),
      text: t("home_tablet_text30_02")
    },
    {
      isActive: false,
      img: "menu",
      title: t("home_tablet_text31_01"),
      titleBack: t("home_tablet_back_text31_01"),
      text: t("home_tablet_text31_02")
    },
    {
      isActive: false,
      img: "clock",
      title: t("home_tablet_text32_01"),
      titleBack: t("home_tablet_back_text32_01"),
      text: t("home_tablet_text32_02")
    },
    {
      isActive: false,
      img: "square",
      title: t("home_tablet_text33_01"),
      titleBack: t("home_tablet_back_text33_01"),
      text: t("home_tablet_text33_02")
    },
    {
      isActive: false,
      img: "report",
      title: t("home_tablet_text34_01"),
      titleBack: t("home_tablet_back_text34_01"),
      text: t("home_tablet_text34_02")
    }
  ]

  return (
    <div className="home-tablet-container">
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <NavBar />
      {showVideo && <VideoPlayer source={videoSource} width={"60%"} btnMarginRight={"20%"} close={() => setShowVideo(false)} />}
      <section id="tedioso" className="tedioso-tablet-section">
        <div className='tedioso-tablet-content'>
          <div className='tedioso-tablet-text-box'>
            <p className='tedioso-tablet-title'>
              <WriteText texts={textsHome} />
            </p>
            <p className='tedioso-tablet-subtitle'>{parse(t("home_tablet_text02"))}</p>
            <p className='tedioso-tablet-text'>
              <span>{parse(t("home_tablet_text03_01"))}</span>
              <br />
              <br />
              <span>{parse(t("home_tablet_text03_02"))}</span>
            </p>
          </div>
          <div className='tedioso-tablet-btn-box'>
            <button
              className='tedioso-tablet-btn'
              onClick={playVideo}
              onMouseEnter={() => setArrow(true)}
              onMouseLeave={() => setArrow(false)}
            >
              {t("text_video")}
              <img
                alt="arrow-right"
                src={arrow ? btnVideoArrow.white : btnVideoArrow.black}
                className="mobile-menu-arrow-right"
              />
            </button>
          </div>
        </div>
        <div className='tedioso-tablet-img-box'>
          <img
            alt="tedioso-img"
            src="/home/bill.svg"
            className="tedioso-tablet-img fade-in"
          />
        </div>
      </section>
      <section id="porcentajes" className="porcentajes-tablet-section">
        <p className='porcentajes-tablet-title'>{parse(t("home_tablet_text05"))}</p>
        <div className='porcentajes-tablet-row'>
          <div className='porcentajes-tablet-stat'>
            <p className='porcentajes-tablet-subtitle'>99%</p>
            <p className='porcentajes-tablet-text'>{parse(`${t("home_text06")}`)}</p>
          </div>
          <div className='porcentajes-tablet-divider'></div>
          <div className='porcentajes-tablet-stat'>
            <p className='porcentajes-tablet-subtitle'>30%</p>
            <p className='porcentajes-tablet-text'>{parse(`${t("home_text07")}`)}</p>
          </div>
          <div className='porcentajes-tablet-divider'></div>
          <div className='porcentajes-tablet-stat'>
            <p className='porcentajes-tablet-subtitle'>{t("home_text08_01")}</p>
            <p className='porcentajes-tablet-text'>{parse(`${t("home_text08_02")}`)}</p>
          </div>
        </div>
      </section>
      <section id="solucion" className="solucion-tablet-section">
        <div className='solucion-tablet-title-box'>
          <p className='solucion-tablet-section-title'>{parse(t("home_text09_01"))}</p>
          <p className='solucion-tablet-title'>{parse(t("home_tablet_text09_02"))}</p>
        </div>
        <div className='solucion-tablet-text-box'>
          <p className='solucion-tablet-text'>
            {parse(t("home_tablet_text10_01"))}
            <br />
            <br />
            {parse(t("home_tablet_text10_02"))}
          </p>
          <p className='solucion-tablet-text'>
            {parse(t("home_tablet_text11_01"))}
            <br />
            <br />
            {parse(t("home_tablet_text11_02"))} <b>{parse(t("home_tablet_text11_03"))}</b>
          </p>
        </div>
      </section>
      <section id="app-movil" className="app-tablet-section">
        <DynamicCardTablet dynamicCards={dynamicCardsAppMobile} />
      </section>
      <section id="web-platform" className="app-tablet-section-02">
        <DynamicCardTablet dynamicCards={dynamicCardsWebPlatform} />
      </section>
      <section id='funcionalidades' className='home-fun-tablet-section'>
        <div className='home-fun-tablet-text-box-container'>
          <div className='home-fun-tablet-titles'>
            <p className='home-fun-tablet-section-title'>{parse(t("home_tablet_text23"))}</p>
            <p className='tedioso-title'>
              <WriteText texts={textsFeatures} />
            </p>
          </div>
          <div className='home-fun-tablet-text-box'>
            <p className='home-fun-tablet-subtitle'>{parse(t("home_tablet_text25"))}</p>
            <div className='home-fun-tablet-columns-box'>
              <p className='home-fun-tablet-text'>
                {parse(t("home_tablet_text26_01"))} <span className='home-fun-tablet-text-green'>{parse(t("home_tablet_text26_02"))}</span> {parse(t("home_tablet_text26_03"))}
              </p>
              <p className='home-fun-tablet-text'>{parse(t("home_tablet_text27_01"))}</p>
            </div>
          </div>
        </div>
        <div className='home-fun-tablet-cards-row'>
          <FuncCardTablet cards={funcCards} />
        </div>
      </section>
      <CtaTablet ctaText={t("cta_text03")} />
      <FooterTablet />
    </div>
  )
}

export default HomeTablet
