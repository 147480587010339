import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './casos_uso_tablet.css'

import NavBar from '../../components/navbar'
import DynamicImage from '../../components/dynamic-image'
import FlipCardIconRow from '../../components/casos_uso/flip-card-icon-row';
import CtaTablet from '../../components/common/cta/tablet'
import FooterTablet from '../../components/common/footer/tablet'

const CasosUsoTablet = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  const images = [
    { image: t("use_cases_dynamic_image_01") },
    { image: t("use_cases_dynamic_image_02") },
    { image: t("use_cases_dynamic_image_03") },
    { image: t("use_cases_dynamic_image_04") }
  ]

  const flipCards = {
    preferences: {
      isTablet: true
    },
    cards: [
      {
        isCurrentCard: false,
        img: "reception",
        title: t("use_cases_text04_00"),
        txtBtn: t("text_read_more"),
        icon: "delivery",
        reverseTitle: t("use_cases_text04_01"),
        description: t("use_cases_text04_02")
      },
      {
        isCurrentCard: false,
        img: "docs",
        title: t("use_cases_text05_00"),
        txtBtn: t("text_read_more"),
        icon: "collection",
        reverseTitle: t("use_cases_text05_01"),
        description: t("use_cases_text05_02")
      },
      {
        isCurrentCard: false,
        img: "plane",
        title: t("use_cases_text06_00"),
        txtBtn: t("text_read_more"),
        icon: "expenses",
        reverseTitle: t("use_cases_text06_01"),
        description: t("use_cases_text06_02")
      },
      {
        isCurrentCard: false,
        img: "shield",
        title: t("use_cases_text07_00"),
        txtBtn: t("text_read_more"),
        icon: "identity",
        reverseTitle: t("use_cases_text07_01"),
        description: t("use_cases_text07_02")
      },
      {
        isCurrentCard: false,
        img: "card",
        title: t("use_cases_text08_00"),
        txtBtn: t("text_read_more"),
        icon: "credit",
        reverseTitle: t("use_cases_text08_01"),
        description: t("use_cases_text08_02")
      },
      {
        isCurrentCard: false,
        img: "phone",
        title: t("use_cases_text09_00"),
        txtBtn: t("text_read_more"),
        icon: "onboarding",
        reverseTitle: t("use_cases_text09_01"),
        description: t("use_cases_text09_02")
      }
    ]
  };

  return (
    <div className="casos-uso-tablet-container">
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <NavBar />
      <section id="inicio" className="casos-uso-tablet-section">
        <div className='casos-uso-tablet-content'>
          <p className='casos-uso-tablet-title'>{parse(`${t("use_cases_text01")}`)}</p>
          <p className='casos-uso-tablet-text'>{parse(t("use_cases_tablet_text02"))}</p>
          <Link onClick={scrollToTop} to="/contacto" className="casos-uso-tablet-btn">
            {t("use_cases_text03")}
            <img
              alt="arrow-right"
              src="/common/arrows/complete/black/right.png"
              className='arrow-btn-image-tablet'
            />
          </Link>
        </div>
        <div className='casos-uso-img-box'>
          <DynamicImage images={images} />
        </div>
      </section>
      <section id="cards" className="casos-uso-tablet-cards-section">
        <FlipCardIconRow flipCards={flipCards} />
      </section>
      <CtaTablet ctaText={t("cta_text04")} />
      <FooterTablet />
    </div>
  )
}

export default CasosUsoTablet