import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './tecnologia_desktop.css'

import LanguageSelect from '../../components/languageSelect/languageSelect'
import VideoPlayer from '../../components/video-player'
import ImageCardTitledDesktop from '../../components/tecnologia/desktop/image-card-titled';
import DynamicScroll from '../../components/dynamic-scroll'
import NumberedImageCardDesktop from '../../components/tecnologia/desktop/numbered-image-card';
import CtaDesktop from '../../components/common/cta/desktop';
import Footer from '../../components/common/footer/desktop'

const TecnologiaDesktop = () => {
	const { t } = useTranslation();

	const scrollToTop = () => window.scrollTo(0, 0);

	const [showVideo, setShowVideo] = useState(false);
	const videoSource = t("home_video")
	const playVideo = () => setShowVideo(true);

	const [arrow, setArrow] = useState(false);
	const [arrowVideo, setArrowVideo] = useState(false);
	const btnArrow = {
		black: "/common/arrows/complete/black/right.png",
		white: "/common/arrows/complete/white/right.png"
	}

	const imageCardsTitled = [
		{
			img: 'start',
			title: t("technology_text03_01"),
			text: t("technology_text03_02")
		},
		{
			img: 'our',
			title: t("technology_text04_01"),
			text: t("technology_text04_02")
		},
		{
			img: 'ai',
			title: t("technology_text05_01"),
			text: t("technology_text05_02")
		}
	]

	const pasoCards = [
		{
			img: "01",
			number: "01",
			title: t("technology_text13_01"),
			text: t("technology_text13_02")
		},
		{
			img: "02",
			number: "02",
			title: t("technology_text14_01"),
			text: t("technology_text14_02")
		},
		{
			img: "03",
			number: "03",
			title: t("technology_text15_01"),
			text: t("technology_text15_02")
		}
	]

	const boxLeft = {
		title: t("technology_text10_01"),
		text: t("technology_text10_02")
	}

	const boxRight = [
		{
			height: t("technology_text11_height_01"), 
			image: t("technology_text11_img_01"),
			icon: "text",
			color: "orange",
			title: t("technology_text11_01"),
			text: [
				t("technology_text11_02"),
				t("technology_text11_03")
			]
		},
		{
			height: t("technology_text11_height_02"), 
			image: t("technology_text11_img_02"),
			icon: "image",
			color: "blue",
			title: t("technology_text11_04"),
			text: [
				t("technology_text11_05"),
				t("technology_text11_06")
			]
		},
		{
			height: t("technology_text11_height_03"), 
			image: t("technology_text11_img_03"),
			icon: "robot",
			color: "green",
			title: t("technology_text11_07"),
			text: [
				t("technology_text11_08"),
				t("technology_text11_09")
			]
		}
	]

	return (
		<div className="tecnologia-container">
			<Helmet>
				<title>Picaioweb</title>
				<meta property="og:title" content="Picaioweb" />
			</Helmet>
			<header className="header">
				<div className='header-content'>
					<div className='header-logo'>
						<Link onClick={scrollToTop} to="/" className="header-logo-link">
							<img
								alt="picaio-logo"
								src="/common/header/logo.png"
							/>
						</Link>
					</div>
					<div class='header-nav-list'>
						<div className='header-nav-views'>
							<Link onClick={scrollToTop} to="/" className="header-link">{t("nav_text01")}</Link>
							<Link onClick={scrollToTop} to="/casos_uso" className="header-link">{t("nav_text02")}</Link>
							<Link onClick={scrollToTop} to="/tecnologia" className="header-link header-link-active">{t("nav_text03")}</Link>
							<Link onClick={scrollToTop} to="/quienes_somos" className="header-link">{t("nav_text04")}</Link>
						</div>
						<div className='header-btn-box'>
							<Link onClick={scrollToTop} to="/contacto" className="header-btn">{t("nav_text05")}</Link>
						</div>
						<div className='header-lang-box'><LanguageSelect /></div>
					</div>
				</div>
			</header>
			{showVideo && <VideoPlayer source={videoSource} width={"60%"} btnMarginRight={"20%"} close={() => setShowVideo(false)} />}
			<section id="magia" className='magia-content'>
				<div className='magia-text-container'>
					<div className='magia-text-box'>
						<p className='magia-title-text'>{parse(t("technology_text01"))}</p>
						<p className='magia-text'>{parse(t("technology_text02"))}</p>
					</div>
					<div className='magia-btns-row'>
						<Link
							onClick={scrollToTop}
							to="/contacto"
							className="magia-btn-demo"
							onMouseEnter={() => setArrow(true)}
							onMouseLeave={() => setArrow(false)}
						>
							{t("text_btn_demo")}
							<img
								alt="arrow-right"
								src={arrow ? btnArrow.white : btnArrow.black}
								className="mobile-menu-arrow-right"
							/>
						</Link>
						<div
							className='magia-btn-video'
							onClick={playVideo}
							onMouseEnter={() => setArrowVideo(true)}
							onMouseLeave={() => setArrowVideo(false)}
						>
							{t("text_video")}
							<img
								alt="arrow-right"
								src={arrowVideo ? btnArrow.black : btnArrow.white}
								className="mobile-menu-arrow-right"
							/>
						</div>
					</div>
				</div>
				<img
					alt="image"
					src="/tec/magic/Ilustration.svg"
					className="magia-image"
				/>
			</section>
			<section id="cards" className='cards-content'>
				{imageCardsTitled.map(e => <ImageCardTitledDesktop key={e.img} card={e} />)}
			</section>
			<section id="rendimiento" className='rendimiento-content'>
				<p className='rendimiento-title'>{t("technology_text06")}</p>
				<div className='rendimiento-row'>
					<div className='rendimiento-row-card'>
						<p className='rendimiento-row-card-title'>99.9%</p>
						<p className='rendimiento-row-card-text'>{parse(t("technology_text07"))}</p>
					</div>
					<div className='rendimiento-row-divider'></div>
					<div className='rendimiento-row-card'>
						<p className='rendimiento-row-card-title'>{parse(t("technology_text08_01"))}</p>
						<p className='rendimiento-row-card-text'>{parse(t("technology_text08_02"))}</p>
					</div>
					<div className='rendimiento-row-divider'></div>
					<div className='rendimiento-row-card'>
						<p className='rendimiento-row-card-title'>{parse(t("technology_text09_01"))}</p>
						<p className='rendimiento-row-card-text'>{parse(t("technology_text09_02"))}</p>
					</div>
				</div>
			</section>
			<section id="reconocimiento" className='reconocimiento-section'>
				<DynamicScroll boxLeft={boxLeft} boxRight={boxRight} />
			</section>
			<section id="paso-paso" className='paso-paso-content'>
				<div className='paso-paso-text-box'>
					<p className='paso-paso-section-title'>{t("technology_text12_01")}</p>
					<div className='paso-paso-title-box'>
						<p className='paso-paso-title'>{t("technology_text12_02")}</p>
						<p className='paso-paso-subtitle'>{t("technology_text12_03")}</p>
					</div>
				</div>
				<div className='paso-paso-cards-row'>{pasoCards.map(e => <NumberedImageCardDesktop card={e} />)}</div>
			</section>
			<section id='haz-mas' className='haz-mas-section'>
				<p className='haz-mas-title'>{parse(t("technology_text16_01"))}</p>
				<p className='haz-mas-subtitle'>{parse(t("technology_text16_02"))}</p>
			</section>
			<section id="conciliacion" className='conciliacion-section'>
				<div className='conciliacion-img-box'>
					<img
						alt="image"
						src={`/tec/mobile_app/${t("technology_text17_img")}.svg`}
						className="conciliacion-img"
					/>
				</div>
				<div className='conciliacion-content'>
					<p className='conciliacion-section-title'>{t("technology_text17_01")}</p>
					<p className='conciliacion-title'>{parse(t("technology_text17_02"))}</p>
					<p className='conciliacion-text'>{parse(t("technology_text17_03"))}</p>
					<p className='conciliacion-text '>{parse(t("technology_text17_04"))}</p>
				</div>
			</section>
			<section id="historial" className='conciliacion-section'>
				<div className='historial-content'>
					<p className='conciliacion-section-title'>{t("technology_text18_01")}</p>
					<p className='conciliacion-title'>{parse(t("technology_text18_02"))}</p>
					<p className='conciliacion-text'>{parse(t("technology_text18_03"))}</p>
					<p className='conciliacion-text '>{parse(t("technology_text18_04"))}</p>
				</div>
				<div className='historial-img-box'>
					<img
						alt="image"
						src={`/tec/history/${t("technology_text18_img")}.svg`}
						className="conciliacion-img"
					/>
				</div>
			</section>
			<section id="reportes" className='conciliacion-section'>
				<div className='conciliacion-img-box'>
					<img
						alt="image"
						src={`/tec/reports/${t("technology_text19_img")}.svg`}
						className="conciliacion-img"
					/>
				</div>
				<div className='conciliacion-content'>
					<p className='conciliacion-section-title'>{t("technology_text19_01")}</p>
					<p className='conciliacion-title'>{parse(t("technology_text19_02"))}</p>
					<p className='conciliacion-text'>{parse(t("technology_text19_03"))}</p>
					<p className='conciliacion-text '>{parse(t("technology_text19_04"))}</p>
				</div>
			</section>
			<CtaDesktop ctaText={t("cta_text05")} />
			<Footer />
		</div>
	)
}

export default TecnologiaDesktop
