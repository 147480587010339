import React, { useState, useEffect } from 'react';

import './styles.css';
import DynamicContent from './dynamic-content';
import DynamicImage from './dynamic-image';

const DynamicCard = (props) => {
  const { preferences, cards } = props.dynamicCards;

  const [curentCardImage, setCurrentCardImage] = useState(cards[0].img);
  const [currentCards, setCurrentCards] = useState(cards);
  useEffect(() => setCurrentCards(cards), [cards]);

  const cardClicked = (card) => {
    setCurrentCardImage(card.img)
    setCurrentCards(currentCards.map(e => e.title === card.title ? { ...e, isCurrentCard: true } : { ...e, isCurrentCard: false }))
  };

  return (
    <div className={preferences.wideImage ? 'dynamic-card-container-02' : 'dynamic-card-container'}>
      <DynamicImage cardImage={curentCardImage} wideImage={preferences.wideImage} />
      <div className={`app-content-box ${preferences.wideImage ? '' : ''}`}>
        <div className="app-titles-content">
          <div className='app-titles-box'>
            <p className='app-section-title'>{preferences.title}</p>
            <p className='app-title'>{preferences.subtitle}</p>
          </div>
        </div>
        <div className="app-cards">
          {currentCards.map(card =>
            <DynamicContent
              key={card.title}
              card={card}
              onCardClicked={cardClicked}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DynamicCard
