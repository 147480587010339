import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import LanguageSelect from '../../components/languageSelect/languageSelect'

import './aviso_privacidad_desktop.css'
import Footer from '../../components/common/footer/desktop'

const AvisoPrivacidadDesktop = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  return (
    <div className="aviso-privacidad-container">
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <header className="header">
        <div className='header-content'>
          <div className='header-logo'>
            <Link onClick={scrollToTop} to="/" className="header-logo-link">
              <img
                alt="picaio-logo"
                src="/common/header/logo.png"
              />
            </Link>
          </div>
          <div class='header-nav-list'>
            <div className='header-nav-views'>
              <Link onClick={scrollToTop} to="/" className="header-link">{t("nav_text01")}</Link>
              <Link onClick={scrollToTop} to="/casos_uso" className="header-link">{t("nav_text02")}</Link>
              <Link onClick={scrollToTop} to="/tecnologia" className="header-link">{t("nav_text03")}</Link>
              <Link onClick={scrollToTop} to="/quienes_somos" className="header-link">{t("nav_text04")}</Link>
            </div>
            <div className='header-btn-box'>
              <Link onClick={scrollToTop} to="/contacto" className="header-btn">{t("nav_text05")}</Link>
            </div>
            <div className='header-lang-box'><LanguageSelect /></div>
          </div>
        </div>
      </header>
      <section className='aviso-privacidad-section'>
        <p className='aviso-privacidad-title'>{t('notice_privacy_text01')}</p>
        <img
          alt="aviso-privacidad-image"
          src="/who_are_we/who_are_we/Ilustration.svg"
          className="aviso-privacidad-image"
        />
        <p className='aviso-privacidad-text'>{t('notice_privacy_text02')}</p>
        <p className='aviso-privacidad-subtitle'>{t('notice_privacy_text03')}</p>
        <p className='aviso-privacidad-text'>{t('notice_privacy_text04')}</p>
        <p className='aviso-privacidad-text'>{t('notice_privacy_text05')}</p>
        <p className='aviso-privacidad-text'>{t('notice_privacy_text06')}</p>
        <p className='aviso-privacidad-text'>{t('notice_privacy_text07')}</p>
        <p className='aviso-privacidad-subtitle'>{t('notice_privacy_text08')}</p>
        <p className='aviso-privacidad-text'>{t('notice_privacy_text09')}</p>
        <p className='aviso-privacidad-subtitle'>{t('notice_privacy_text10')}</p>
        <p className='aviso-privacidad-text'>{t('notice_privacy_text11')}</p>
        <p className='aviso-privacidad-subtitle'>{t('notice_privacy_text12')}</p>
        <p className='aviso-privacidad-text'>{t('notice_privacy_text13')}</p>
        <p className='aviso-privacidad-subtitle'>{t('notice_privacy_text14')}</p>
        <p className='aviso-privacidad-text'>{t('notice_privacy_text15')}</p>
      </section>
      <Footer />
    </div>
  )
}

export default AvisoPrivacidadDesktop
