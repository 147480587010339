import React, { useState, useEffect, useRef } from 'react';

import './styles.css';

const DynamicImage = (props) => {
  const { images } = props;

  const [currentImages, setCurrentImages] = useState(images);
  useEffect(() => setCurrentImages(images), [images]);

  let index = 0
  const [currentImage, setCurrentImage] = useState(images[0]);

  useEffect(() => {
    const customInterval = setInterval(() => {
      if (index === 3) {
        index = 0
      } else {
        index = index + 1
      }
      setCurrentImage(currentImages[index])
    }, 2000);

    return () => clearInterval(customInterval)
  }, [currentImages, images]);

  return (
    <img
      alt='dynamic-image'
      src={`/casos-uso/${currentImage.image}.svg`}
      className='dynamic-image'
    />
  )
}

export default DynamicImage
