import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

import './quienes_somos_desktop.css'

import LanguageSelect from '../../components/languageSelect/languageSelect'
import OurValuesCardDesktop from '../../components/quienes_somos/desktop/our-values-card'
import DynamicHeader from '../../components/dynamic-header'
import PhotoCardDesktop from '../../components/quienes_somos/desktop/photo-card'
import CtaDesktop from '../../components/common/cta/desktop'
import Footer from '../../components/common/footer/desktop'

const QuienesSomosDesktop = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  const ourValuesCardFirstRow = [
    {
      img: "geniality",
      title: t("about_us_text04_01"),
      text: t("about_us_text04_02")
    },
    {
      img: "professionalism",
      title: t("about_us_text05_01"),
      text: t("about_us_text05_02")
    },
    {
      img: "passion",
      title: t("about_us_text06_01"),
      text: t("about_us_text06_02")
    }
  ]

  const ourValuesCardSecondRow = [
    {
      img: "proactivity",
      title: t("about_us_text07_01"),
      text: t("about_us_text07_02")
    },
    {
      img: "reliability",
      title: t("about_us_text08_01"),
      text: t("about_us_text08_02")
    }
  ]

  const dynamicHeader = {
    isMobile: false,
    titles: [
      t("about_us_text09_02"),
      t("about_us_text09_03"),
      t("about_us_text09_04")
    ]
  }

  const ourTeamFirstRow = [
    {
      img: "alfredo",
      title: t("about_us_text11_01"),
      text: t("about_us_text11_02"),
      highlighted: "https://www.linkedin.com/in/alfredojcabral/"
    },
    {
      img: "miyeli",
      title: t("about_us_text12_01"),
      text: t("about_us_text12_02"),
      highlighted: "https://www.linkedin.com/in/miyelli-o-76912b184/"
    },
    {
      img: "chepe",
      title: t("about_us_text13_01"),
      text: t("about_us_text13_02"),
      highlighted: "https://www.linkedin.com/in/cheperuiz/"
    }
  ]

  const ourTeamSecondRow = [
    {
      img: "carlos",
      title: t("about_us_text14_01"),
      text: t("about_us_text14_02"),
      highlighted: "https://www.linkedin.com/in/carlos-alberto-quintanilla-olmos-7a5590114/"
    },
    {
      img: "mario",
      title: t("about_us_text15_01"),
      text: t("about_us_text15_02"),
      highlighted: "https://www.linkedin.com/in/marioramirezfuentes/"
    },
    {
      img: "laura",
      title: t("about_us_text16_01"),
      text: t("about_us_text16_02"),
      highlighted: "https://www.linkedin.com/in/laura-jimeneg-ui-ux/"
    }
  ]

  return (
    <div className="quienes-somos-container">
      <Helmet>
        <title>Picaioweb</title>
        <meta property="og:title" content="Picaioweb" />
      </Helmet>
      <header className="header">
        <div className='header-content'>
          <div className='header-logo'>
            <Link onClick={scrollToTop} to="/" className="header-logo-link">
              <img
                alt="picaio-logo"
                src="/common/header/logo.png"
              />
            </Link>
          </div>
          <div class='header-nav-list'>
            <div className='header-nav-views'>
              <Link onClick={scrollToTop} to="/" className="header-link">{t("nav_text01")}</Link>
              <Link onClick={scrollToTop} to="/casos_uso" className="header-link">{t("nav_text02")}</Link>
              <Link onClick={scrollToTop} to="/tecnologia" className="header-link">{t("nav_text03")}</Link>
              <Link onClick={scrollToTop} to="/quienes_somos" className="header-link header-link-active">{t("nav_text04")}</Link>
            </div>
            <div className='header-btn-box'>
              <Link onClick={scrollToTop} to="/contacto" className="header-btn">{t("nav_text05")}</Link>
            </div>
            <div className='header-lang-box'><LanguageSelect /></div>
          </div>
        </div>
      </header>
      <section
        id="our-mission"
        className="our-mission-section"
        style={{
          backgroundImage: `url(/quienes-somos/desktop/${t("about_us_bg_01")}.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '1440px 921px'
        }}
      >
        <div className='our-mission-text-box'>
          <p className='our-mission-title'>{parse(t("about_us_text01_01"))}</p>
          <p className='our-mission-text'>{parse(t("about_us_text01_02"))}</p>
        </div>
      </section>
      <section id="who-are-we" className="who-are-we-section">
        <p className='who-are-we-title'>{t("about_us_text02_01")}</p>
        <img
          alt="who-are-we-img"
          src="/who_are_we/who_are_we/Ilustration.svg"
          className="who-are-we-img"
        />
        <div className='who-are-we-text-box'>
          <p className='who-are-we-text'>
            {parse(t("about_us_text02_02"))} <b className='who-are-we-text-highlighted'>{parse(t("about_us_text02_03"))}</b>
            <br />
            <br />
            {parse(t("about_us_text02_04"))}
            <br />
            <br />
            {parse(t("about_us_text02_05"))}
          </p>
          <p className='who-are-we-text'>
            {parse(t("about_us_text02_06"))}
            <br />
            <br />
            {parse(t("about_us_text02_07"))} <b>{parse(t("about_us_text02_08"))}</b>
          </p>
        </div>
      </section>
      <section id="our-values" className='our-values-section'>
        <p className='our-values-title'>{t("about_us_text03")}</p>
        <div className='our-values-cards'>
          <div className='our-values-row'>
            {ourValuesCardFirstRow.map(e => <OurValuesCardDesktop card={e} />)}
          </div>
          <div className='our-values-row'>
            {ourValuesCardSecondRow.map(e => <OurValuesCardDesktop card={e} />)}
          </div>
        </div>
      </section>
      <section id="office" className='office-section'>
        <div className='office-content'>
          <p className='office-section-title'>{t("about_us_text09_01")}</p>
          <div className='office-text-box'>
            <DynamicHeader dynamicHeader={dynamicHeader} />
            <p className='office-text'>{t("about_us_text09_05")}</p>
          </div>
        </div>
      </section>
      <section id="our-team" className='our-team-section'>
        <div className='our-team-text-box'>
          <p className='our-team-title'>{parse(t("about_us_text10_01"))}</p>
          <p className='our-team-text'>{parse(t("about_us_text10_02"))}</p>
        </div>
        <div className='our-team-cards'>
          <div className='our-team-row'>
            {ourTeamFirstRow.map(e => <PhotoCardDesktop card={e} />)}
          </div>
          <div className='our-team-row'>
            {ourTeamSecondRow.map(e => <PhotoCardDesktop card={e} />)}
          </div>
        </div>
      </section>
      <CtaDesktop ctaText={t("cta_text06")} />
      <Footer />
    </div>
  )
}

export default QuienesSomosDesktop
