import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useMediaQuery } from 'react-responsive'

import './styles.css'

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

const languageMap = {
  es: { lg: "Español", flag: "mx", label: "Español", dir: "ltr", active: true },
  en: { lg: "English", flag: "us", label: "English", dir: "ltr", active: false },
};

const LanguageSelect = (props) => {
  const { mobile } = props;
  const selected = localStorage.getItem("i18nextLng") || "es";
  const { t } = useTranslation();

  const verySmallDevice = useMediaQuery({ query: "(min-width: 360px)" });
  const smallDevice = useMediaQuery({ query: "(min-width: 744px)" });
  const desktop = useMediaQuery({ query: "(min-width: 1440px)" });

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        style={
          mobile
            ? {
              display: "flex",
              alignContent: "center",
              width: "100%",
              justifyContent: "space-between",
              backgroundColor: "#1E1E1E",
            }
            : {}
        }
      >
        <div
          style={
            mobile
              ? { marginLeft: "20px", display: "flex", flexDirection: "row" }
              : { display: "flex", flexDirection: "row" }
          }
        >
          <img
            alt="flag"
            src={`/common/flags/${languageMap[selected].flag}.png`}
            style={{ marginRight: "15px", marginTop: "4px", width: '30px', height: '20px' }}
          />
          <span style={{ textTransform: "capitalize" }} >{languageMap[selected].lg}</span>
        </div>
        <img
          alt="flag"
          src={`/common/arrows/half/white/down.png`}
          style={
            mobile
              ? { marginRight: "20px", width: "15px" }
              : { marginLeft: "10px", width: "15px" }
          }
        />
      </Button>
      {
        !!menuAnchor && desktop ?
          <Popover
            open={!!menuAnchor}
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div>
              <List style={{ backgroundColor: "#1E1E1E" }}>
                <ListSubheader style={{ color: "wheat" }}>
                  {t("select_language")}
                </ListSubheader>
                {Object.keys(languageMap)?.map((item) => (
                  <ListItem
                    button
                    key={item}
                    onClick={() => {
                      i18next.changeLanguage(item);
                      setMenuAnchor(null);
                    }}
                  >
                    {languageMap[item].label}
                  </ListItem>
                ))}
              </List>
            </div>
          </Popover>
          :
          (!!menuAnchor && smallDevice) || (!!menuAnchor && verySmallDevice) ?
            <div className='backdrop' style={smallDevice ? { maxWidth: '744px' } : { maxWidth: '360px' }}>
              <div className="list-container">
                <List className="custom-list">
                  <div className="first-item"></div>
                  {Object.keys(languageMap)?.map((item, index) => (
                    <ListItem
                      button
                      key={item}
                      onClick={() => {
                        i18next.changeLanguage(item);
                        setMenuAnchor(null);
                      }}
                      style={index + 1 === Object.keys(languageMap).length ? { padding: '25px 0 25px 0' } : { borderBottom: '1px white solid', padding: '25px 0 25px 0' }}
                    >
                      <img
                        alt="flag"
                        src={`/common/check/${Boolean(t("current_lang") == languageMap[item].lg) ? 'checkblue-selected' : 'checkblue'}.svg`}
                        style={{ marginRight: "25px", width: '16px', height: '16px' }}
                      />
                      <img
                        alt="flag"
                        src={`/common/flags/${languageMap[item].flag}.png`}
                        style={{ marginRight: "15px", width: '36px', height: '24px' }}
                      />
                      <span style={{ textTransform: "capitalize", fontSize: '16px', fontWeight: '600' }}>{languageMap[item].lg}</span>
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
            :
            <></>
      }
    </div>
  );
};

export default LanguageSelect;
