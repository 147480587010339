import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import LanguageSelect from '../../components/languageSelect/languageSelect'
import emailjs from '@emailjs/browser';

import './contacto_desktop.css'
import Alert from '../../components/alert'
import Footer from '../../components/common/footer/desktop'

const ContactoDesktop = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  const image = t("contact_image_01")

  const [arrow, setArrow] = useState(false);
  const btnArrow = {
    black: "/common/arrows/complete/black/right.png",
    white: "/common/arrows/complete/white/right.png"
  }

  const form = useRef();

  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [company, setCompany] = useState("");
  const [validCompany, setValidCompany] = useState(false);
  const [message, setMessage] = useState("");
  const [validMessage, setValidMessage] = useState(false);

  const reNumber = /^\d+$/
  const reEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  const handleInputValidation = (input) => {
    if (input === 'name') {
      if (name.length <= 4) {
        setValidName(false);
      } else {
        setValidName(true);
      }
    }

    if (input === 'email') {
      if (!reEmail.test(email.toLowerCase())) {
        setValidEmail(false);
      } else {
        setValidEmail(true);
      }
    }

    if (input === 'phone') {
      if (phone.length <= 9) {
        setValidPhone(false);
      } else {
        setValidPhone(true);
      }
    }

    if (input === 'company') {
      if (company.length <= 3) {
        setValidCompany(false);
      } else {
        setValidCompany(true);
      }
    }

    if (input === 'message') {
      if (message.length <= 9) {
        setValidMessage(false);
      } else {
        setValidMessage(true);
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5ojequ5', 'template_vw159j2', form.current, 'vHLlVgXq_zqXvw4sJ')
      .then((result) => {
        setCurrentAlert({
          title: t("text_message01"),
          text: t("text_message02"),
          img: '/check/check',
          invert: true
        });
        toggleAlert()
        cleanFields()
      }, (error) => {
        setCurrentAlert({
          title: t("contact_text09"),
          text: t("contact_text10"),
          img: 'side-menu/close'
        });
      });
  }

  const cleanFields = () => {
    setName("")
    setValidName(false)
    setEmail("")
    setValidEmail(false)
    setPhone("")
    setValidPhone(false)
    setCompany("")
    setValidCompany(false)
    setMessage("")
    setValidMessage(false)
  }

  const [currentAlert, setCurrentAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const toggleAlert = () => {
    if (!showAlert) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }

  return (
    <>
      {showAlert && <Alert texts={currentAlert} emitAlert={toggleAlert} />}
      <div className='contacto-container'>
        <Helmet>
          <title>Picaio</title>
          <meta property="og:title" content="Picaio" />
        </Helmet>
        <header className="header">
          <div className='header-content'>
            <div className='header-logo'>
              <Link onClick={scrollToTop} to="/" className="header-logo-link">
                <img
                  alt="picaio-logo"
                  src="/common/header/logo.png"
                />
              </Link>
            </div>
            <div class='header-nav-list'>
              <div className='header-nav-views'>
                <Link onClick={scrollToTop} to="/" className="header-link">{t("nav_text01")}</Link>
                <Link onClick={scrollToTop} to="/casos_uso" className="header-link">{t("nav_text02")}</Link>
                <Link onClick={scrollToTop} to="/tecnologia" className="header-link">{t("nav_text03")}</Link>
                <Link onClick={scrollToTop} to="/quienes_somos" className="header-link">{t("nav_text04")}</Link>
              </div>
              <div className='header-btn-box'>
                <Link onClick={scrollToTop} to="/contacto" className="header-btn">{t("nav_text05")}</Link>
              </div>
              <div className='header-lang-box'><LanguageSelect /></div>
            </div>
          </div>
        </header>
        <section id="contact" className="contact-section">
          <div className='contact-text-box'>
            <p className='contact-title'>{t("contact_text01_01")}</p>
            <p className='contact-text'>{t("contact_text01_02")}</p>
          </div>
          <div className='contact-content'>
            <div className='contact-img-box'>
              <img
                alt="contact-img"
                src={`/contact/${image}.svg`}
                className="contact-img"
              />
            </div>
            <form ref={form} autocomplete="off" className="contact-form" onSubmit={handleSubmit}>
              <div className='contact-form-input-row'>
                <div className='contact-form-input-column contact-form-input-column-01'>
                  <div className='contact-form-input-01'>
                    <label className="contact-form-label" for="name">{t("contact_text02_01")}</label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={name}
                      placeholder={t("contact_text02_02")}
                      onChange={(e) => setName(e.target.value)}
                      onBlur={() => handleInputValidation('name')}
                      className={name.length === 0 ? "contact-form-input" : validName ? "contact-form-input-success" : "contact-form-input-error"}
                    />
                  </div>
                  <div className='contact-form-input-02'>
                    <label className="contact-form-label" for="email">{t("contact_text03_01")}</label>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      onBlur={() => handleInputValidation('email')}
                      placeholder={t("contact_text03_02")}
                      className={email.length === 0 ? "contact-form-input" : validEmail ? "contact-form-input-success" : "contact-form-input-error"}
                    />
                  </div>
                </div>
                <div className='contact-form-input-column contact-form-input-column-02'>
                  <div className='contact-form-input-01'>
                    <label className="contact-form-label" for="phone">{t("contact_text04_01")}</label>
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      value={phone}
                      onChange={(e) => reNumber.test(e.target.value) && phone.length <= 10 && e.target.value.length < 11 || e.target.value === '' ? setPhone(e.target.value) : ''}
                      onBlur={() => handleInputValidation('phone')}
                      placeholder={t("contact_text04_02")}
                      className={phone.length === 0 ? "contact-form-input" : validPhone ? "contact-form-input-success" : "contact-form-input-error"}
                    />
                  </div>
                  <div className='contact-form-input-02'>
                    <label className="contact-form-label" for="company">{t("contact_text05_01")}</label>
                    <input
                      id="company"
                      name="company"
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      onBlur={() => handleInputValidation('company')}
                      placeholder={t("contact_text05_02")}
                      className={company.length === 0 ? "contact-form-input" : validCompany ? "contact-form-input-success" : "contact-form-input-error"}
                    />
                  </div>
                </div>
              </div>
              <div className='contact-textarea-box'>
                <label className="contact-form-label" for="message">{t("contact_text06_01")}</label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onBlur={() => handleInputValidation('message')}
                  placeholder={t("contact_text06_02")}
                  className={message.length === 0 ? "contact-form-textarea" : validMessage ? "contact-form-textarea-success" : "contact-form-textarea-error"}
                ></textarea>
              </div>
              <div className='contact-btn-box'>
                <button
                  className='contact-btn'
                  type='submit'
                  onMouseEnter={() => setArrow(true)}
                  onMouseLeave={() => setArrow(false)}
                  disabled={Boolean(!validName || !validEmail || !validCompany || !validPhone || !validMessage)}
                >
                  <span className='contact-btn-text'>
                    {t("contact_text07")}
                  </span>
                  <img
                    alt="arrow-right"
                    src={arrow ? btnArrow.white : btnArrow.black}
                  />
                </button>
              </div>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default ContactoDesktop
