import React from 'react';
import parse from 'html-react-parser';

import './styles.css';

const ImageCardTitledTablet = (props) => {
  const { card } = props;

  return (
    <div className='image-card-titled-tablet-container'>
      <img
        alt="image"
        src={`/tec/cards/${card.img}.svg`}
        className="image-card-titled-tablet-image"
      />
      <div className='image-card-titled-tablet-content'>
        <p className='image-card-titled-tablet-title'>{parse(card.title)}</p>
        <p className='image-card-titled-tablet-text'>{parse(card.text)}</p>
      </div>
    </div>
  )
}

export default ImageCardTitledTablet
