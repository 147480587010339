import React from 'react'
import { useMediaQuery } from 'react-responsive'

import AvisoPrivacidadDesktop from './aviso_privacidad/aviso_privacidad_desktop'
import AvisoPrivacidadTablet from './aviso_privacidad/aviso_privacidad_tablet'
import AvisoPrivacidadMobile from './aviso_privacidad/aviso_privacidad_mobile'

const AvisoPrivacidad = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return <>
    {isMobile && !isTablet && !isDesktop && <AvisoPrivacidadMobile />}
    {isTablet && !isDesktop && <AvisoPrivacidadTablet />}
    {isDesktop && <AvisoPrivacidadDesktop />}
  </>
}

export default AvisoPrivacidad
