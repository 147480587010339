import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser'

import './styles.css';

const FuncCardMobile = (props) => {
  const { cards } = props;

  const [currentCards, setCurrentCards] = useState(cards);
  useEffect(() => setCurrentCards(cards), [cards]);

  const cardClicked = (cardTitle) =>
    setCurrentCards(currentCards.map(e => e.title === cardTitle ? { ...e, isActive: true } : { ...e, isActive: false }));

  return (
    <>
      {
        currentCards.map(e =>
          <div
            className={`func-card-mobile-container ${e.isActive ? 'func-card-mobile-active' : 'func-card-mobile-deactive'}`}
            onClick={() => cardClicked(e.title)}
          >
            {
              e.isActive ?
                <>
                  <div className='func-card-mobile-frame-active'>
                    <img
                      alt="func-card-mobile-icon"
                      src={`/home/func/icon/black/${e.img}.svg`}
                      className="func-card-mobile-icon"
                    />
                    <img
                      alt="func-card-mobile-arrow"
                      src={`/home/func/arrow/down.svg`}
                      className="func-card-mobile-arrow-img rotateOn"
                    />
                  </div>
                  <p className='func-card-mobile-title-active text-fade'>{parse(e.titleBack)}</p>
                  <p className='func-card-mobile-text-active text-fade'>{parse(e.text)}</p>
                </>
                :
                <>
                  <div className='func-card-mobile-frame-deactive'>
                    <img
                      alt="func-card-mobile-img"
                      src={`/home/func/icon/blue/${e.img}.svg`}
                      className="func-card-mobile-icon"
                    />
                    <p className='func-card-mobile-title-deactive'>{parse(e.title)}</p>
                  </div>
                  <img
                    alt="func-card-mobile-arrow"
                    src={`/home/func/arrow/down.svg`}
                    className="func-card-mobile-arrow-img rotateOff"
                  />
                </>
            }
          </div>
        )
      }
    </>
  )
}

export default FuncCardMobile
