import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import GraphemeSplitter from "grapheme-splitter";

import './styles.css';

const DynamicHeader = (props) => {
  const { isMobile, titles } = props.dynamicHeader;

  const [currentTitles, setCurrentTitles] = useState(titles);
  useEffect(() => setCurrentTitles(titles), [titles]);

  const stringSplitter = (string) => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
  };

  return (
    <p className={isMobile ? 'dynamic-header-mobile-title' : 'dynamic-header-title'}>
      <Typewriter
        options={{
          strings: currentTitles,
          autoStart: true,
          loop: true,
          cursor: '',
          stringSplitter
        }}
      />
    </p>
  )
}

export default DynamicHeader
