import { useMediaQuery } from 'react-responsive';

import QuienesSomosTablet from './quienes_somos/quienes_somos_tablet';
import QuienesSomosDesktop from './quienes_somos/quienes_somos_desktop';
import QuienesSomosMobile from './quienes_somos/quienes_somos_mobile';

const QuienesSomos = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return <>
    {isMobile && !isTablet && !isDesktop && <QuienesSomosMobile />}
    {isTablet && !isDesktop && <QuienesSomosTablet />}
    {isDesktop && <QuienesSomosDesktop />}
  </>
}

export default QuienesSomos
