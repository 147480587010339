import React, { useState, useEffect } from 'react';

import './styles.css';

const DynamicImage = (props) => {
  const { cardImage, wideImage } = props;

  const [currentImage, setCurrentImage] = useState(cardImage);
  useEffect(() => setCurrentImage(cardImage), [cardImage]);

  return (
    <img
      alt="app-img"
      src={`solucion/${currentImage}.svg`}
      className={wideImage ? 'wide-image' : 'app-img'}
    />
  )
}

export default DynamicImage
