import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser'

import './styles.css';

const DynamicContent = (props) => {
  const { card } = props;

  const [isCurrentCard, setIsCurrentCard] = useState(card.isCurrentCard);
  useEffect(() => setIsCurrentCard(card.isCurrentCard), [card.isCurrentCard]);

  const cardClicked = () => props.onCardClicked(card);

  return (
    <>
      {
        isCurrentCard &&
        <img
          alt='dynamic-card-tablet-img'
          src={`/solucion/${card.img}.svg`}
          className='dynamic-card-tablet-img fade'
          style={!!card.width ? { width: card.width } : {}}
        />
      }
      <div
        className={isCurrentCard ? `dynamic-card-tablet-box dynamic-card-tablet-box-active-${card.bgc}` : 'dynamic-card-tablet-box dynamic-card-tablet-box-deactive'}
        onClick={cardClicked}
      >
        <div className='dynamic-card-content'>
          <img
            alt="dynamic-card-tablet-img"
            src={`/solucion/${card.icon}-${isCurrentCard ? "active" : "deactive"}.svg`}
            className="dynamic-card-tablet-icon"
          />
          <p className={`fade ${isCurrentCard ? 'dynamic-card-tablet-text-active' : 'dynamic-card-tablet-text-deactive'}`} style={!isCurrentCard ? { display: 'flex' } : {}}>
            {
              isCurrentCard ?
                card.description.map(e =>
                  !!e.strong ?
                    <strong>{parse(e.text)}</strong> :
                    <span>{parse(e.text)}</span>
                )
                :
                <strong>{parse(card.title)}</strong>
            }
          </p>
        </div>
        <img
          alt='arrow-img'
          src={`/home/dynamic-card/arrows/arrow_down.svg`}
          className={isCurrentCard ? 'dynamic-card-arrow-img-active rotateOn' : 'dynamic-card-arrow-img rotateOff'}
        />
      </div>
    </>
  )
}

export default DynamicContent
