import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './styles.css';
import WriteText from '../../../write-text';

const CtaDesktop = (props) => {
  const { t } = useTranslation();
  const scrollToTop = () => window.scrollTo(0, 0);

  const { ctaText } = props;

  const texts = [
    t("cta_text01_01"),
    t("cta_text01_01"),
    t("cta_text01_02")
  ]

  const [arrow, setArrow] = useState(false);
  const btnVideoArrow = {
    black: "/common/arrows/complete/black/right.png",
    white: "/common/arrows/complete/white/right.png"
  }

  return (
    <section id="cta" className='cta-desktop-section' style={{
      backgroundImage: `url(/cta/bg.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: "bottom",
      backgroundSize: '1440px 436px',
      maxWidth: '1440px',
      width: '100%',
      alignSelf: 'center'
    }}>
      <div className='cta-desktop-container'>
        <div className='cta-desktop-content-box'>
          <p className='cta-desktop-title'>
            <WriteText texts={texts} />
          </p>
          <p className='cta-desktop-subtitle'>{parse(t("cta_text02"))}</p>
          <p className='cta-desktop-text'>{parse(ctaText)}</p>
          <Link
            onClick={scrollToTop}
            to="/contacto"
            className="cta-desktop-btn"
            onMouseEnter={() => setArrow(true)}
            onMouseLeave={() => setArrow(false)}
          >
            {t("cta_text_btn")}
            <img
              alt="arrow-right"
              src={arrow ? btnVideoArrow.white : btnVideoArrow.black}
            />
          </Link>
        </div>
        <div className='cta-desktop-img-box'>
          <img
            alt="cta-desktop-img"
            src="/cta/cta.svg"
            className="cta-desktop-img"
          />
        </div>
      </div>
    </section>
  )
}

export default CtaDesktop
