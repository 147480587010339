import React from 'react';
import parse from 'html-react-parser';

import './styles.css';

const ImageCardTitledDesktop = (props) => {
  const { card } = props;

  return (
    <div className='image-card-titled-desktop-container'>
      <img
        alt="image"
        src={`/tec/cards/${card.img}.svg`}
        className="image-card-titled-desktop-image"
      />
      <div className='image-card-titled-desktop-content'>
        <p className='image-card-titled-desktop-title'>{parse(card.title)}</p>
        <p className='image-card-titled-desktop-text'>{parse(card.text)}</p>
      </div>
    </div>
  )
}

export default ImageCardTitledDesktop
