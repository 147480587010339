import React from 'react';
import { useMediaQuery } from 'react-responsive'
import parse from 'html-react-parser';

import './styles.css';

const NumberedImageCard = (props) => {
  const { card } = props;

  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return (
    <div className='numbered-img-card-container' style={{width: isDesktop ? '33%' : isTablet ? '388px' : '328px'}}>
      <div className='numbered-img-card-img-box'>
        <img
          alt="image"
          src={`/tec/how/${card.img}.svg`}
          className="numbered-img-card-img"
        />
      </div>
      <div className='numbered-img-card-text-box' style={{width: isTablet ? '335px' : 'auto'}}>
        <div className='numbered-img-card-title-box'>
          <span className='numbered-img-card-number-text'>{card.number}</span>
          <span className='numbered-img-card-title'> {parse(card.title)}</span>
        </div>
        <span className='numbered-img-card-text'>{parse(card.text)}</span>
      </div>
    </div>
  )
}

export default NumberedImageCard
