import { useMediaQuery } from 'react-responsive';

import HomeDesktop from './home/home_desktop';
import HomeTablet from './home/home_tablet';
import HomeMobile from './home/home_mobile';

const Home = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return <>
    {isMobile && !isDesktop && !isTablet && <HomeMobile />}
    {isTablet && !isDesktop && <HomeTablet />}
    {isDesktop && <HomeDesktop />}
  </>
}

export default Home
