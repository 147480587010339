import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from "classnames";
import Icon from '@mdi/react';
import {
  mdiMenu,
  mdiPhone,
  mdiAccountGroup,
  mdiLightbulb,
  mdiChatQuestion
} from '@mdi/js';

import './styles.css'
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../languageSelect/languageSelect';

const NavBar = (props) => {
  const { mobile } = props;
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  const toggleMenu = () => {
    if (!showMenu) {
      setShowMenu(true);
    } else {
      setShowMenu(false)
    }
  }

  const closeMenu = () => setShowMenu(false);

  return (
    <div className="mobile-nav-container" style={mobile ? { maxWidth: '360px' } : {}}>
      <div className="mobile-nav-header">
        <div className="mobile-nav-image">
          <Link onClick={scrollToTop} to="/" className="mobile-home-navlink">
            <img
              alt="image"
              src="/common/header/logo.png"
              className="mobile-home-image"
            />
          </Link>
        </div>
        <div className="nav-button-container">
          <div
            className="nav-button"
            onClick={toggleMenu}
          >
            <Icon
              path={mdiMenu}
              size={1}
            />
          </div>
        </div>
      </div>
      <div
        onClick={toggleMenu}
        className={classNames({
          'mobile-nav-dim-screen': true,
          'hidden': !showMenu
        })}
        style={mobile ? { maxWidth: '360px' } : {}}
      ></div>
      {/* MENU */}
      <div
        className={classNames({
          'mobile-menu-container': true,
          'hidden': !showMenu
        })}
      >
        <div className="mobile-menu-items-container">
          <div className='mobile-menu-close-icon-container'>
            <img
              alt="menu-close-icon"
              src="/common/side-menu/close.png"
              className="mobile-menu-close-image"
              onClick={closeMenu}
            />
          </div>
          <div className="mobile-menu-logo-container">
            <Link onClick={scrollToTop} to="/">
              <img
                alt="image"
                src="/common/header/logo.png"
                className="mobile-menu-logo-image"
              />
            </Link>
          </div>
          <div className='mobile-menu-btn-container'>
            <Link
              to={props.isRootPage ? '' : '/contacto'}
              className="mobile-nav-menu-btn"
              onClick={() => { scrollToTop(), toggleMenu() }}
            >
              {t("text_btn_demo")}
              <img
                alt="arrow-right"
                src="/common/arrows/complete/black/right.png"
                className="mobile-menu-arrow-right"
              />
            </Link>
          </div>
          <div className="mobile-menu-item-container">
            <Link
              to={props.isRootPage ? '' : '/'}
              className="mobile-nav-menu-item"
              onClick={() => { scrollToTop(), toggleMenu() }}
            >
              {t("nav_text01")}
            </Link>
          </div>
          <div className="mobile-menu-item-container">
            <Link
              to={props.isRootPage ? '' : '/casos_uso'}
              className="mobile-nav-menu-item"
              onClick={() => { scrollToTop(), toggleMenu() }}
            >
              {t("nav_text02")}
            </Link>
          </div>
          <div className="mobile-menu-item-container">
            <Link
              to={props.isRootPage ? '' : '/tecnologia'}
              className="mobile-nav-menu-item"
              onClick={() => { scrollToTop(), toggleMenu() }}
            >
              {t("nav_text03")}
            </Link>
          </div>
          <div className="mobile-menu-item-container">
            <Link
              to={props.isRootPage ? '' : '/quienes_somos'}
              className="mobile-nav-menu-item"
              onClick={() => { scrollToTop(), toggleMenu() }}
            >
              {t("nav_text04")}
            </Link>
          </div>
          <div className="mobile-menu-item-container">
            <Link
              to={props.isRootPage ? '' : '/contacto'}
              className="mobile-nav-menu-item"
              onClick={() => { scrollToTop(), toggleMenu() }}
            >
              {t("nav_text05")}
            </Link>
          </div>
          <div className="mobile-menu-linkedin-container">
            <a href='https://www.linkedin.com/company/picaioai' target="_blank">
              <img
                alt='linkedin-logo'
                src='/common/side-menu/linkedin.png'
                className='mobile-menu-linkedin'
              />
            </a>
          </div>
        </div>
      </div>
      <LanguageSelect mobile={true} />
    </div>
  )
}

export default NavBar
