import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './styles.css';
import WriteText from '../../../write-text';

const CtaTablet = (props) => {
  const { t } = useTranslation();
  const scrollToTop = () => window.scrollTo(0, 0);

  const { ctaText } = props;

  const texts = [
    t("cta_text01_01"),
    t("cta_text01_01"),
    t("cta_text01_02")
  ]

  return <section id="cta" className='cta-tablet-section'>
    <div className='cta-tablet-header-container'>
      <p className='cta-tablet-title'>
        <WriteText texts={texts} />
      </p>
      <p className='cta-tablet-subtitle'>{parse(t("cta_tablet_text02"))}</p>
    </div>
    <p className='cta-tablet-text'>{parse(ctaText)}</p>
    <Link onClick={scrollToTop} to="/contacto" className="cta-tablet-btn">
      {t("cta_text_btn")}
      <img
        alt="arrow-right"
        src="/common/arrows/complete/black/right.png"
        className="mobile-menu-arrow-right"
      />
    </Link>
  </section>
}

export default CtaTablet
